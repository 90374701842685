import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const getNotifications = async () => {
    const route = `/notifications`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const markNotificationAsRead = async (id) => {
    const route = `/notifications/${id}`;
    const token = user.getToken();
    const request = {
        method: "Post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

export default {
    getNotifications,
    markNotificationAsRead
}