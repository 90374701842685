import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  Radio,
  RadioGroup,
  Grid,
  GridItem,
  Input,
  Image,
} from "@chakra-ui/react";
import { MdCancel } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

import userApi from "services/api/user"
import userServices from "services/storage/user";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const schema = yup.object({
    artist_full_name: yup.string().required(),
    artist_alias: yup.string().required(),
    style: yup.string().required(),
    fileString: yup.string().required(),
});

const ArtistRequest = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState({});
  const [request, setRequest] = useState({});
  const [images, setImages] = useState([]);
  const [urlImages, setUrlImages] = useState([]);

  useEffect(() => {}, []);

  const handleImageChange = (e) => {
    const fileList = e.target.files;
    if(fileList.length === 0) return
    const imageArray = [];
    const newFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.startsWith('image/') && file.size <= 2 * 1024 * 1024) {
        const url = URL.createObjectURL(file);
        imageArray.push(url);
        newFiles.push(file);
      }
      if (imageArray.length >= 20) {
        break; // Break loop if limit is reached
      }
    }
    setValue("fileString", JSON.stringify(imageArray))
    setUrlImages(imageArray)
    setImages(newFiles);
  };

  console.log(errors)

  const onSubmit = async (values) => {
    console.log(images.length)
    const formData = new FormData();
    formData.append("style", values.style);
    formData.append("artist_alias", values.artist_alias);
    formData.append("full_name", values.artist_full_name);
    images.map((image) => {
        formData.append("evidence[]", image)
    })

    await userApi.artistRequest(formData).then((response) => {
      if (response.success) {
        Swal.fire({
          title: "Ticket resuelto",
          icon: "success",
          text:
          `La solicitud ha sido enviada exitosamente. Te notificaremos cuando recibas una respuesta`,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: "#4fd1c5",
          didClose: () => {history.push("/admin/artist-tickets")}
        });
      } else {
        Swal.fire({
          title: "Algo salió mal",
          icon: "warning",
          text:
            "Ocurrio un problema al enviar la solicitud. Recargue la página y vuelva a intentarlo.",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Cerrar",
          cancelButtonColor: "#4fd1c5",
        });
      }
    })
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {userServices.getRole() === "artist" && <Redirect to="/app/home" /> }
      <Flex
        flexDirection={"column"}
        pt={{ base: "120px", md: "75px" }}
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        minWidth={"26rem"}
      >
        <Grid
          my="2rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={"15px"}
          width={"70%"}
        >
          <GridItem>
            <Flex justifyContent="start">
              <FormControl>
                <FormLabel id="artist_alias">Alias de artista</FormLabel>
                <Input
                  {...register("artist_alias")}
                  fontSize="sm"
                  borderRadius="15px"
                  type="text"
                  placeholder="Alias de artista"
                  size="lg"
                />
              </FormControl>
            </Flex>
          </GridItem>
          <GridItem>
            <Flex justifyContent="start">
              <FormControl>
                <FormLabel id="style">Estilo artístico</FormLabel>
                <Input
                  {...register("style")}
                  fontSize="sm"
                  borderRadius="15px"
                  placeholder="Estilo artístico"
                  size="lg"
                  type="text"
                />
              </FormControl>
            </Flex>
          </GridItem>
          <GridItem colSpan={1} rowStart={1}>
            <Flex justifyContent="start">
              <FormControl>
                <FormLabel id="artist_full_name">
                  Nombre completo del solicitante
                </FormLabel>
                <Input
                  {...register("artist_full_name")}
                  fontSize="sm"
                  borderRadius="15px"
                  type="text"
                  placeholder="Nombre completo del solicitante"
                  size="lg"
                />
              </FormControl>
            </Flex>
          </GridItem>
        </Grid>
        <FormControl width={"70%"}>
          <FormLabel color="secondaryLight.500" _hover={{color: "secondaryLight.700"}} id="artist_full_name" role="button">{"Subir evidencia(s)"}</FormLabel>
          <Input
            type="file"
            onChange={handleImageChange}
            multiple
            accept="image/*"
            hidden
          />
          <Input
            {...register("fileString")}
            hidden
          />
          <Flex flexWrap="wrap">
            {urlImages.map((image, index) => (
              <Box key={index} p="2" maxW="200px">
                <Image src={image} alt={`image_${index}`} />
              </Box>
            ))}
          </Flex>
        </FormControl>
        <Box>
          <Button
            type="submit"
            colorScheme={"primaryLight"}
            fontSize="10px"
            color="white"
            fontWeight="bold"
            ms="4px"
            w="10rem"
            h="45"
            mb="24px"
          >
            ENVIAR
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export default ArtistRequest;
