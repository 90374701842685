// Chakra imports
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  CircularProgress,
  InputGroup,
  Input,
  InputLeftElement,
  FormControl,
  Stack,
  IconButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TableRowUsers from "./TableRowUsers";
import { TablePaginator } from "components/Tables/TablePaginator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2"

import adminApi from "services/api/admin";

import { FaSearch } from "react-icons/fa";

const DataTable = ({
  title,
  captions,
  data,
  loading,
  paginationData,
  onChangePage,
  onSearch,
}) => {
  const textColor = useColorModeValue("white");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const history = useHistory();

  const { register, handleSubmit, getValues } = useForm();

  const {
    current_page,
    from,
    last_page,
    to,
    total,
  } = paginationData;

  const canGoFurther = current_page < last_page;
  const canGoFewer = current_page > 1

  const onDetails = (userId) => {
    // return <Redirect to={"/admin/user/" + userId} />
    history.push("/admin/user/" + userId)
  };

  const onDelete = (userId) => {
    Swal.fire({
      type: "warning",
      title: "Precaución",
      text: "Si elimina este usuario sus datos y transacciones pendientes podrían perderse",
      confirmButtonColor: "red",
      confirmButtonText: "Eliminar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#4fd1c5",
    }).then(async ({isConfirmed}) => {
      if(isConfirmed){
        await adminApi.deleteUser(userId).then((response) => {
          if(response.success){
            Swal.fire({
              icon: "success",
              title: "Acción realizada con éxito.",
              text: "El usuario fue eliminado correctamente",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#4fd1c5",
            })
          } else {
            Swal.fire({
              title: "Algo salió mal",
              icon: "warning",
              text:
                "Ocurrio un problema al eliminar este usuario. Recargue la página y vuelva a intentarlo.",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Cerrar",
              cancelButtonColor: "#4fd1c5",
            });
          }
        })
      }
    })
  };

  const actions = [
    { id: "details", title: "Ver perfil", action: onDetails },
    {
      id: "delete",
      title: "Eliminar",
      action: onDelete,
    },
  ];

  const onSubmit = (values) => {
    onSearch(values.searchParam);
  };

  const paginationHandler = (page) => {
    const search = getValues("searchParam") ?? "";
    console.log(search)
    onChangePage(page, search);
  };

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      overflowY="scroll"
      padding="0"
    >
      <CardHeader p="6px 22px 22px 22px" bg={secondaryColor}>
        <Stack spacing={4} w="100%">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            {title}
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl w={{ base: "100%", md: "30%" }}>
              <InputGroup>
                <InputLeftElement mt=".2rem" role="button">
                  <IconButton
                    type="submit"
                    colorScheme="primaryLight"
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: "transparent" }}
                  >
                    <FaSearch size="1.5rem" />
                  </IconButton>
                </InputLeftElement>
                <Input
                  fontSize="md"
                  borderRadius="15px"
                  type="text"
                  placeholder="Buscar"
                  size="lg"
                  textColor={textColor}
                  _placeholder={{ color: "gray.300" }}
                  {...register("searchParam")}
                />
              </InputGroup>
            </FormControl>
          </form>
        </Stack>
      </CardHeader>
      <CardBody p="0px 22px 22px 22px">
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan="100%">
                  <Flex justifyContent="center">
                    <CircularProgress
                      isIndeterminate
                      color="primaryLight.500"
                    />
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <>
                {total >= 1 ? (
                  <>
                    {data.map((row) => {
                      row.created_at = row.created_at.split(" ")[0];
                      return (
                        <TableRowUsers
                          key={`${row.email}-${row.username}`}
                          id={row.id}
                          name={row.username}
                          logo={row.profile_pic}
                          email={row.email}
                          domain={row.role}
                          status={!row.deleted_at}
                          date={row.created_at}
                          actions={actions}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Tr>
                    <Td colSpan="100%">
                      <Flex justifyContent="center">
                        <Text color="gray.400">
                          No hay registros que mostrar
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
          <TablePaginator paginationData={paginationData} onChangePage={paginationHandler} />
        </Table>
      </CardBody>
    </Card>
  );
};

export default DataTable;
