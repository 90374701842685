const fullMonths = new Map([
    [1, "enero"],
    [2, "febrero"],
    [3, "marzo"],
    [4, "abril"],
    [5, "mayo"],
    [6, "junio"],
    [7, "julio"],
    [8, "agosto"],
    [9, "septiembre"],
    [10, "octubre"],
    [11, "noviembre"],
    [12, "diciembre"],
]);

const mmmMonths = new Map([
    [1, "ene"],
    [2, "feb"],
    [3, "mar"],
    [4, "abr"],
    [5, "may"],
    [6, "jun"],
    [7, "jul"],
    [8, "ago"],
    [9, "sep"],
    [10, "oct"],
    [11, "nov"],
    [12, "dic"],
]);

const parseddMMMYYYYWithHour = (input) => {
    input = input.split(" ")
    let date = input[0].split("-");
    let time = input[1].split(":");

    return `${date[2]} ${mmmMonths.get(parseInt(date[1]))} ${date[0]} a las ${time[0]}:${time[1]}` 
}

export default {
    parseddMMMYYYYWithHour,
}