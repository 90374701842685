import { Box, useStyleConfig, useColorModeValue } from "@chakra-ui/react";
function Card(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });
  // Pass the computed styles into the `__css` prop
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  return (
    <Box
      __css={{
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: primaryColor,
          borderRadius: "24px",
        },
        ...styles,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Card;
