import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Divider,
  FormControl,
  FormLabel,
  Spinner,
  Textarea,
  HStack,
  Radio,
  RadioGroup,
  Grid,
  GridItem,
  Badge,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

import RequesterCard from "./components/RequesterCard";

import userApi from "services/api/user";
import adminApi from "services/api/admin";
import EvidenceGrid from "./components/EvidenceGrid";

const schema = yup.object({
  status: yup.string().required(),
  response: yup.string(),
});

const RequestDetails = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState({});
  const [request, setRequest] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await adminApi.getTicket(props.match.params.id).then((response) => {
      if (response.success) {
        setRequest({
          ...response.ticket,
          evidence: JSON.parse(response.ticket.evidence),
        });
        getRequester(response.ticket.user_id);
      } else {
        Swal.fire({
          title: "Algo salió mal",
          icon: "warning",
          text:
            "Ocurrio un problema al consultar la solicitud. Es posible que esta solicitud no exista.",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Cerrar",
          cancelButtonColor: "#4fd1c5",
        });
      }
    });
  };

  const getRequester = async (userId) => {
    await userApi.getUser(userId).then((response) => {
      if (response.success) setUser(response.user);
    });
  };

  const evaluateStatus = (evaluationOutputs) => {
    if (request.status === "pending") return evaluationOutputs[0];
    else if (request.status === "accepted") return evaluationOutputs[1];
    else if (request.status === "rejected") return evaluationOutputs[2];
    else return evaluationOutputs[3];
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("status", values.status);
    formData.append("response", values.response);
    formData.append("_method", "PUT");
    await adminApi
      .resolveTicket(formData, props.match.params.id)
      .then((response) => {
        if (response.success) {
          Swal.fire({
            title: "Ticket resuelto",
            icon: "success",
            text:
            `La solicitud ha sido ${values.status === "accepted" ? 'aceptada' : 'rechazada'} exitosamente.`,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: "#4fd1c5",
            didClose: () => {history.push("/admin/artist-tickets")}
          });
        } else {
          Swal.fire({
            title: "Algo salió mal",
            icon: "warning",
            text:
              "Ocurrio un problema al consultar la solicitud. Recargue la página y vuelva a intentarlo.",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            cancelButtonColor: "#4fd1c5",
          });
        }
      });
  };

  return (
    <>
      <Flex
        flexDirection={"column"}
        pt={{ base: "120px", md: "75px" }}
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        minWidth={"26rem"}
      >
        {!!Object.keys(request).length && !!Object.keys(user).length ? (
          <>
            <Flex direction={"column"} alignItems="center" w="70%">
              <RequesterCard data={user} />
              <Grid
                my="2rem"
                width="100%"
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
              >
                <GridItem>
                  <Flex justifyContent="center">
                    <Text fontWeight="bold">{"Alias de artista:"}</Text>
                    <Text fontWeight="500" color={secondaryColor}>
                      {request.artist_alias}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex justifyContent="center">
                    <Text fontWeight="bold">{"Estilo artístico: "}</Text>
                    <Text fontWeight="500" color={secondaryColor}>
                      {request.style}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex justifyContent="center">
                    <Text fontWeight="bold">
                      {"Nombre completo del solicitante: "}
                    </Text>
                    <Text fontWeight="500" color={secondaryColor}>
                      {request.full_name}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex justifyContent="center">
                    <Text fontWeight="bold">{"Fecha de solicitud: "}</Text>
                    <Text fontWeight="500" color={secondaryColor}>
                      {request.created_at.substring(0, 10)}
                    </Text>
                  </Flex>
                </GridItem>
              </Grid>
              <EvidenceGrid evidence={request.evidence} />
            </Flex>
            <Divider borderColor={primaryColor} borderWidth="1px" my="2rem" />
            <Flex justifyContent="center" w="70%">
              <Badge
                bg={evaluateStatus([
                  warningStatus,
                  successStatus,
                  dangerStatus,
                  noStatus,
                ])}
                color={request.status ? "white" : colorStatus}
                fontSize="16px"
                p="3px 10px"
                borderRadius="8px"
              >
                {evaluateStatus([
                  "Pendiente",
                  "Aceptado",
                  "Rechazado",
                  "Desconocido",
                ])}
              </Badge>
            </Flex>
            {request.response_date ? (
              <Flex direction={"column"} alignItems="center" w="70%">
                <Box>
                  <Text fontWeight="bold">{"Fecha de resolución:"}</Text>
                  <Text fontWeight="500" color={secondaryColor}>
                    {request.response_date}
                  </Text>
                </Box>
                {!!request.response && (
                  <Box w={"100%"}>
                    <FormControl mb="24px" ms="4px" id="response">
                      <FormLabel fontSize="sm" fontWeight="normal">
                        <Text fontWeight="bold">{"Mensaje:"}</Text>
                      </FormLabel>
                      <Textarea
                        fontSize="sm"
                        borderRadius="15px"
                        type="text"
                        placeholder="Respuesta"
                        size="lg"
                        multiline
                        rows={6}
                        readOnly
                        value={request.response}
                      />
                    </FormControl>
                  </Box>
                )}
              </Flex>
            ) : (
              <Flex direction={"column"} alignItems="center" w="70%">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ width: "100%" }}
                >
                  <FormControl mb="24px" ms="4px" id="status" name="status">
                    <FormLabel fontSize="sm" fontWeight="normal">
                      <Text fontWeight="bold">Estatus</Text>
                    </FormLabel>
                    <RadioGroup>
                      <HStack spacing="80px" name="status">
                        <Radio
                          value="accepted"
                          name="accepted"
                          {...register("status")}
                        >
                          Aceptar
                        </Radio>
                        <Radio
                          value="rejected"
                          name="rejected"
                          {...register("status")}
                        >
                          Rechazar
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl mb="24px" ms="4px" id="response">
                    <FormLabel fontSize="sm" fontWeight="normal">
                      <Text fontWeight="bold">Respuesta</Text>
                    </FormLabel>
                    <Textarea
                      fontSize="sm"
                      borderRadius="15px"
                      type="text"
                      placeholder="Respuesta"
                      size="lg"
                      multiline
                      rows={6}
                      {...register("response")}
                    />
                  </FormControl>
                  <Box>
                    <Button
                      type="submit"
                      colorScheme={"primaryLight"}
                      fontSize="10px"
                      color="white"
                      fontWeight="bold"
                      ms="4px"
                      w="10rem"
                      h="45"
                      mb="24px"
                    >
                      ENVIAR
                    </Button>
                  </Box>
                </form>
              </Flex>
            )}
          </>
        ) : (
          <Spinner mt="4rem" size="xl" color={secondaryColor} thickness="3px" />
        )}
      </Flex>
    </>
  );
};

export default RequestDetails;
