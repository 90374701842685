import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
} from "@chakra-ui/react";
import React from "react";

import { FaEllipsisV } from "react-icons/fa";

function TableRowUsers(props) {
  const {
    logo,
    name,
    email,
    subdomain,
    domain,
    status,
    date,
    id,
    actions = [],
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("danger.500", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {domain}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {subdomain}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={
            status
              ? "green.400"
              : bgStatus
          }
          color={
            status
              ? "white"
              : colorStatus
          }
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status ? "Activo" : "Inactivo"}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>
      <Td>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisV />}
            color={textColor} 
            variant="outline"
          />
          <MenuList>
            {actions.map((action, key) => {
                if(!status && action.id === "delete") return <></>
              return (
                <MenuItem key={key} onClick={() => action.action(id)} textColor={textColor}>
                  {action.title}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TableRowUsers;
