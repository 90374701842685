import React from "react";
import {
  Flex,
  Text,
  Button,
  Divider,
  useColorModeValue
} from "@chakra-ui/react";

const QnASection = (props) => {
    const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
    const secondaryColor = useColorModeValue(
      "secondaryLight.500",
      "secondaryDark.500"
    );

  const { questions, isArtCreator, onAnswer } = props;

  return (
    <Flex w="70%" ps=".5rem" direction="column">
      <Text fontWeight="bold" fontSize={22}>Preguntas sobre esta obra</Text>
      {!isArtCreator && (
        <Button colorScheme="primaryLight" onClick={onAnswer}>
          Haz una pregunta
        </Button>
      )}
      {questions.map((question) => {
        return (
          <>
            {(!!question.answer || isArtCreator) && (
              <>
                <Divider borderColor={primaryColor} borderWidth="1px" my="1.3rem" />
                <Text fontWeight="500">{question.question}</Text>
                {!!question.answer ? (
                  <Text>{"- " + question.answer}</Text>
                ) : (
                  <Button
                    colorScheme="primaryLight"
                    onClick={() => onAnswer(question)}
                  >
                    Responde a esta pregunta
                  </Button>
                )}
              </>
            )}
          </>
        );
      })}
    </Flex>
  );
};

export default QnASection;
