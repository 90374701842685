import React, { useEffect } from "react";
import {
  Flex,
  Text,
  Avatar,
  Box,
  useColorModeValue,
  Grid,
  GridItem,
  Badge,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import userServices from "services/storage/user";

const MessageCard = (props) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const optionText = useColorModeValue("gray.700", "gray.700");
  const helperTextColor = useColorModeValue("gray.600", "gray.600");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const history = useHistory();

  const user =
    userServices.getId() === props.message.user_a_id
      ? props.message.user_b
      : props.message.user_a;
  const messageData = props.message;

  useEffect(() => {
    userServices.getId();
  }, []);
  console.log(user);
  return (
    <Box
      w="36rem"
      h="6rem"
      bgColor="primaryLight.100"
      borderRadius="25px"
      my=".3rem"
      role="button"
      onClick={() => {
        history.push({
          pathname: `/app/message/user/${messageData.id}`,
          state: {
            receiverId: user.id,
          },
        });
      }}
    >
      <Flex h="100%" alignItems="center">
        <Avatar mx={".75rem"} h="5rem" w="5rem" src={user.profile_pic} />
        <Grid
          py={".6rem"}
          w="100%"
          h="100%"
          templateRows={"1fr 2fr"}
          templateColumns={"4fr 1fr"}
        >
          <GridItem>
            <Text fontWeight={600} color={optionText}>
              {user.username}
            </Text>
          </GridItem>
          <GridItem>
            <Badge
              colorScheme="secondaryLight"
              borderRadius="5px"
              color="primaryLight.600"
            >
              {messageData.unread ? "Pendiente" : ""}
            </Badge>
          </GridItem>
          <GridItem colSpan={2}>
            <Text color={helperTextColor}>
              {messageData.last_message.content ||
                "Aun no hay mensajes en esta conversación"}
            </Text>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
};

export default MessageCard;
