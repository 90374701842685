import React from 'react'
import {
    Flex,
    Grid,
    GridItem,
    Box,
    Text,
    Image,
    useColorModeValue,
    Button,
  } from "@chakra-ui/react";

const RequesterCard = ({data}) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600")
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(3, 1fr)"
      gap="20px"
      p="24px"
      bgColor={primaryColor}
      borderRadius={{ sm: "10px", md: "20px" }}
    >
      <GridItem rowSpan={3} colStart={1} rowStart={1}>
        <Box>
          <Image borderRadius="15" height="12rem" src={data.profile_pic} />
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={1}>
        <Box>
          <Text
            color={headerTextColor}
            fontWeight="semibold"
            fontSize={{ base: "16", md: 18, xl: "20" }}
          >
            {data.nickname}
          </Text>
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={3} colSpan={2}>
        <Box>
          <Text>{data.description}</Text>
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={2}>
        <Box>
          <Text>{data.username}</Text>
        </Box>
      </GridItem>
      <GridItem colStart={3} rowStart={1}>
        <Box>
          <Text>Miembro desde:</Text> <Text color={headerTextColor} fontWeight="semibold" >{`${data.created_at.substring(0, 10)}`}</Text>
        </Box>
      </GridItem>
    </Grid>
  )
}

export default RequesterCard