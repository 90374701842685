/*eslint-disable*/
// chakra imports
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import IconBox from "components/Icons/IconBox";
import { CreativeTimLogo } from "components/Icons/Icons";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useHistory } from "react-router-dom";

import messagesApi from "services/api/messages";
import userService from "services/storage/user";

// this function creates the links and collapses that appear in the sidebar (left menu)

const SidebarContent = ({ logoText, enabledMessages }) => {
  // Chakra Color Mode
  const activeBg = useColorModeValue("white", "gray.700");
  const inactiveBg = useColorModeValue("white", "gray.700");
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("gray.200", "gray.400");
  const [messages, setMessages] = useState([]);
  const userID = userService.getId();

  const history = useHistory()

  useEffect(() => {
    getMessages();

    const interval = setInterval(() => {
      getMessages();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const getMessages = async () => {
    await messagesApi.getMessages().then((response) => {
      if (response.success) {
        setMessages(response.conversations);
      }
    });
  };

  return (
    <>
      <Box pt={"25px"} mb="12px">
        <Box
          display="flex"
          lineHeight="100%"
          mb="30px"
          fontWeight="bold"
          justifyContent="center"
          alignItems="center"
          fontSize="11px"
        >
          <ChatIcon w="20px" h="20px" me="10px" color={inactiveColor} />
          <Text fontSize="sm" mt="3px" color={inactiveColor}>
            {logoText}
          </Text>
        </Box>
        <Separator></Separator>
      </Box>
      <Stack direction="column" mb="40px">
        <Box>
          {enabledMessages ? (
            <>
              {!messages.length ? (
                <Text color="white" textAlign="center">
                  Aun no tienes mensajes por leer
                </Text>
              ) : (
                <>
                  {messages.map(({ user_a, user_b, id }) => {
                    const _user = user_a.id === userID ? user_b : user_a;
                    return (
                      <Flex
                        my=".3rem"
                        role="button"
                        onClick={() => {
                          history.push({
                            pathname: `/app/message/user/${id}`,
                            state: {
                              receiverId: _user.id,
                            },
                          });
                          history.go(0);
                        }}
                      >
                        <Avatar
                          src={_user.profile_pic}
                          h="40px"
                          w="40px"
                          me="12px"
                        />
                        <Text color={inactiveColor} my="auto" fontSize="lg">
                          {_user.username}
                        </Text>
                      </Flex>
                    );
                  })}
                  <Flex my=".3rem" role="button" justifyContent="center">
                    <Link to="/app/messages" my="auto" fontSize="lg">
                      <Text
                        color={inactiveColor}
                        _hover={{ color: "gray.600" }}
                      >
                        Ir a la bandeja
                      </Text>
                    </Link>
                  </Flex>
                </>
              )}
            </>
          ) : (
            <Text color="white" textAlign="center">
              Tus mensajes están deshabilitados. Conectate con el mundo
              habilitandolos desde el menú de configuración
            </Text>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default SidebarContent;
