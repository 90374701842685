import React, { useState, useEffect } from "react";
import Table from "./components/TransactionsTable";
import { tablesTableData } from "variables/general";
import salesServices from "services/api/sales";
import { Box, Flex, CircularProgress } from "@chakra-ui/react";

import userServices from "services/storage/user";

const UserList = () => {
  const perPage = 25;
  const [loader, setLoader] = useState(true);
  const [transactionList, setTransactionList] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    getTransactionList();
  }, []);

  const getTransactionList = async (page = 1, search = "") => {
    const user = userServices.getRole();
    switch (user) {
      case "admin":
        setHeaders([
          "ID",
          "Comprador",
          "Vendedor",
          "Obra",
          "Estatus",
          "Fecha de inicio",
          "Acciones",
        ]);
        break;
      case "user":
        setHeaders([
          "ID",
          "Vendedor",
          "Obra",
          "Estatus",
          "Fecha de inicio",
          "Acciones",
        ]);

        break;
      case "artist":
        setHeaders([
          "ID",
          "Comprador",
          "Obra",
          "Estatus",
          "Fecha de inicio",
          "Acciones",
        ]);

        break;
    }
    const start = (page - 1) * perPage + 1;
    await salesServices
      .countTransactions()
      .then(async (countResponse) => {
        if (countResponse.success) {
          const totalTransactions = countResponse.total_count;
          // const totalTransactions = 10;
          await salesServices
            .getTransactions(start, search)
            .then((response) => {
              if (response.success) {
                setTransactionList(response.transactions);
                const evaluateLastItem = perPage > totalTransactions ? totalTransactions : page === metadata.last_page ? totalTransactions : page * perPage
                let _metadata = {
                  current_page: page,
                  from: start,
                  last_page: Math.floor(totalTransactions / perPage) + 1,
                  to: evaluateLastItem,
                  total: totalTransactions
                };
                setMetadata({ ..._metadata });
              }
            });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleChangePage = async (page, searchParam = "") => {
    setLoader(true);
    setTransactionList([]);
    setMetadata({});
    await getTransactionList(page, searchParam);
  };

  return (
    <Flex
      flexDirection="column"
      marginTop={{ base: "120px", md: "75px" }}
      direction="column"
      justifyContent="center"
      id="container"
    >
      <Flex height={"70vh"} p="0px">
        <Table
          title={"Lista de transacciones"}
          captions={headers}
          data={transactionList}
          loading={loader}
          paginationData={metadata}
          onChangePage={handleChangePage}
        />
      </Flex>
    </Flex>
  );
};

export default UserList;
