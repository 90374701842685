import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const getFeed = async (start) => {
    let route = `/feed?noi=25&start=${start}`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getFeedSearch = async (start, search) => {
    let route = `/search?noi=25&start=${start}${search}`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getComment = async (id) => {
    const route = `/posts/${id}/comments`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const sendComment = async (postId, comment) => {
    const route = `/posts/${postId}/comments`;
    const token = user.getToken();
    const requestBody = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: JSON.stringify({ content: comment }),
    };
  
    return await sendRequest(route, requestBody);
}

const getSocial = async (start) => {
    const token = user.getToken();
    const _user = user.getUser();
    const route = `/users/${_user.id}/posts?noi=25&start=${start}`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const countSocial = async () => {
    const _user = user.getUser();
    let route = `/users/${_user.id}/posts/count`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const likePost = async (id, status, type) => {
    const route = `/${type}s/${id}/${status ? 'like' : 'dislike'}`;
    const token = user.getToken();
    const requestBody = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
  
    return await sendRequest(route, requestBody);

}

const getTags = async () => {
    const _user = user.getUser();
    let route = `/tags`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const createPost = async (data) => {
    const route = `/create-post`;
    const token = user.getToken();
    const requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };
  
    return await sendRequest(route, requestBody);
}

export default {
    getFeed,
    getFeedSearch,
    getComment,
    sendComment,
    getSocial,
    countSocial,
    likePost,
    getTags,
    createPost
}

