import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Text, useColorModeValue, Grid, GridItem, Icon } from "@chakra-ui/react";

import { BellIcon } from "@chakra-ui/icons";

import { MdPersonAdd, MdCurrencyExchange, MdPalette, MdNotifications, MdQuestionMark } from "react-icons/md";

import notificationsApi from "services/api/notifications";
import authApi from "services/api/auth";

const NotificationCard = (props) => {
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const history = useHistory()

  const { notification } = props;

  const getLink = () => {
    switch (notification.item_type) {
      case "transaction":
        return `/app/transactions/${notification.entity_id}/following`;
      case "user":
        return `/app/user/${notification.entity_id}`;
      case "question":
        return `/app/sales/${notification.entity_id}/details`;
      case "artwork":
        return `/app/sales/${notification.entity_id}/details`;
      default:
        return "app/home";
    }
  };

  const getIcon = () => {
    switch (notification.item_type) {
      case "transaction":
        return <MdCurrencyExchange size="5rem" color={!!notification.pivot.read_at ? "#a0aec0" : "inherit"}/>;
      case "user":
        return <MdPersonAdd size="5rem" color={!!notification.pivot.read_at ? "#a0aec0" : "inherit"}/>;
      case "question":
        return <MdQuestionMark size="5rem" color={!!notification.pivot.read_at ? "#a0aec0" : "inherit"}/>;
      case "artwork":
        return <MdPalette size="5rem" color={!!notification.pivot.read_at ? "#a0aec0" : "inherit"}/>;
      default:
        return <MdNotifications size="5rem" color={!!notification.pivot.read_at ? "#a0aec0" : "inherit"}/>;
    }
  };

  const viewNotification = async () => {
    await notificationsApi
      .markNotificationAsRead(notification.id)
      .then((response) => {
        if(notification.title === "Te has convertido en artista") {
          authApi.fetchUser()
        }
      });
  };

  return (
    <Box
      textAlign="center"
      h={{ base: "8rem" }}
      width={{ base: "8rem", md: "40rem" }}
      my=".25rem"
      mx="1rem"
      textColor={secondaryColor}
      fontWeight={600}
      borderRadius={{ sm: "10px", md: "20px" }}
      borderWidth={!!notification.pivot.read_at ? "3px" : "5px"}
      borderStyle="solid"
      borderColor={!!notification.pivot.read_at ? "gray.400" : secondaryColor}
    >
      <Grid
        templateColumns={"1fr 2fr 1fr"}
        templateRows={"repeat(3, 1fr)"}
        height={"100%"}
      >
        <GridItem
          rowSpan={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon
            as={getIcon}
            boxSize={{ base: 8, md: 14 }}
            me={{ base: "2rem", md: 0 }}
          />
        </GridItem>
        <GridItem colStart={2} rowStart={1}>
          <Text color="gray.600" textAlign="start" mt=".5rem">
            {notification.title}
          </Text>
        </GridItem>
        <GridItem colStart={2} rowStart={2} rowSpan={2}>
          <Text color="gray.500" fontWeight={400} textAlign="start" mt=".5rem">
            {notification.message}
          </Text>
        </GridItem>
        <GridItem colStart={3} rowStart={3}>
          <Link to={getLink} onClick={() => viewNotification()}>
            <Text
              color="primaryLight.500"
              fontWeight={400}
              textAlign="end"
              me="1rem"
              mt=".5rem"
            >
              Detalles
            </Text>
          </Link>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default NotificationCard;
