import React, {useRef, useEffect} from "react";
import {
  Flex,
  Text,
  Avatar,
  Box,
  useColorModeValue,
  Grid,
  GridItem,
  Badge,
  Textarea,
  FormControl,
  FormLabel,
  Button,
} from "@chakra-ui/react";

import userServices from "services/storage/user";

import dateTimeUtils from "utils/dateTime";

const ConversationBox = (props) => {
  const optionText = useColorModeValue("gray.700", "gray.700");
  const grayColor = useColorModeValue("gray.600", "gray.600");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const focusedMessage = useRef(null)

  const { messages } = props;

  const currentUserId = userServices.getId();

  useEffect(() => {
    if(!!messages.length) focusedMessage.current.scrollIntoView();
  }, [messages])

  return (
    <Box
      w="100%"
      p=".5rem"
      h={{ base: "20rem", md: "30rem" }}
      overflowY="scroll"
      bgColor="white"
    >
      <Flex
        w="100%"
        // maxHeight="100%"
        justifyContent="center"
        alignItems="center"
        direction="column"
        // overflowY="scroll"
      >
        {messages.map((message, i) => {
          const loggedUserMessage = currentUserId === message.sender;
          const nextSenderIsSame = messages[i + 1]?.sender === message.sender;
          return (
            <Flex
              mx=".5rem"
              mb={nextSenderIsSame ? ".3rem" : ".75rem"}
              maxWidth="80%"
              alignSelf={loggedUserMessage ? "end" : "start"}
              direction="column"
            >
              <Box
                borderRadius={
                  loggedUserMessage ? "12px 12px 0 12px" : "12px 12px 12px 0"
                }
                bgColor={loggedUserMessage ? primaryColor : secondaryColor}
                ref={(i === messages.length - 1) ? focusedMessage : null}
                // borderWidth={(i === messages.length - 1) ? '5px' : '0px'}
                px=".5rem"
                py=".3rem"
                w="fit-content"
                alignSelf={loggedUserMessage ? "end" : "start"}
              >
                <Text color={loggedUserMessage ? "white" : "white"}>
                  {message.content}
                </Text>
              </Box>
              {!nextSenderIsSame && (
                <Text
                  fontSize={"12px"}
                  textAlign={loggedUserMessage ? "end" : "start"}
                  color={grayColor}
                >
                  {message.date === "now" ? "ahora" : dateTimeUtils.parseddMMMYYYYWithHour(message.date)}
                </Text>
              )}
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default ConversationBox;
