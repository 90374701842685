import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const getUser = async (id) => {
    const route = id ? `/users/${id}` : '/user';
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const updateUser = async (data) => {
    const route = '/update-user';
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
        body: data
    };
    const response = await sendRequest(route, request);
    if(response.success){
        user.updateUser({...response.user})
        console.log(response.user)
    }
    return response
}

const updateUserPfp = async (data) => {
    const route = '/update-user';
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
        body: data
    };
    const response = await sendRequest(route, request);
    if(response.success) {
        console.log(response);
    }
    return response
} 

const toggleAllowMessages = async () => {
    const userData = user.getUser()
    const data = JSON.stringify({allow_messages: !userData.allow_messages})
    const route = '/update-user';
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
        body: data
    };
    const response = await sendRequest(route, request);
    if(response.success){
        user.updateUser({...response.user})
        console.log(response.user)
    }
    return response
}

const updatePassword = async (data) => {
    const route = '/update-password';
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: data
    };
    return await sendRequest(route, request);
}

const deleteUser = async (data) => {
    const route = '/delete-user';
    const token = user.getToken();
    const request = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await sendRequest(route, request);
    if(response.success) user.logout()
    return response
}

const getUserPosts = async (userId) => {
    const route = `/users/${userId}/posts`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const artistRequest = async (data) => {
    const route = '/create-ticket';
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
        body: data
    };
    return await sendRequest(route, request);

}

const followUser = async (id) => {
    const route = `/users/${id}/follow`;
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
    };
    return await sendRequest(route, request);

}

const unfollowUser = async (id) => {
    const route = `/users/${id}/unfollow`;
    const token = user.getToken();
    const request = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*"
        },
    };
    return await sendRequest(route, request);

}

export default {
    getUser,
    updateUser,
    toggleAllowMessages,
    updateUserPfp,
    updatePassword,
    deleteUser,
    getUserPosts,
    artistRequest, 
    followUser,
    unfollowUser
}