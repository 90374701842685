import { userData } from "data/store";

const isAuth = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  return !!user?.token;
};

const isAdmin = () => {
  const user = JSON.parse(localStorage.getItem('data'));
  return !!(user?.role === "admin");
}

const getToken = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  return user?.token;
};

const getUsername = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  return user?.username || "Unknown user";
};

const getUser = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  var _user = {
    id: user.id,
    username: user.username,
    nickname: user.nickname,
    profilePicture: user.profile_pic,
    allow_messages: !!user.allow_messages,
  };
  return _user;
};

const updateUser = (data) => {
  const user = JSON.parse(localStorage.getItem("data"));
  const updatedUser = {
    ...user,
    ...data
  }
  localStorage.setItem("data", JSON.stringify(updatedUser))
  return updatedUser
}

const getId = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  return user?.id || 0;

}

const getRole = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  // console.log(user.role)
  return user?.role || "none";

}

const logout = () => localStorage.removeItem("data");

export default {
  isAuth,
  getUsername,
  getUser,
  logout,
  getToken,
  updateUser,
  isAdmin,
  getId,
  getRole,
};
