import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import MessageCard from "./components/MessageCard";

import userApi from "services/api/user";
import messagesApi from "services/api/messages";

const Messages = (props) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600");
  const optionHoveredText = useColorModeValue("gray.200", "gray.200");
  const optionText = useColorModeValue("gray.600", "gray.600");
  const helperTextColor = useColorModeValue("gray.400", "gray.400");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  const [user, setUser] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getUser();
    getMessages();
  }, []);

  const getMessages = async () => {
    await messagesApi.getAllMessages().then((response) => {
      if (response.success) {
        setMessages(response.conversations);
      }
    });
  };

  const getUser = async () => {
    await userApi.getUser().then((response) => {
      console.log(response);
      setUser(response);
    });
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {Object.keys(user).length ? (
        <>
          {messages.map((message, i) => (
            <MessageCard
              key={i}
              message={message}
            />
          ))}
        </>
      ) : (
        <Spinner mt="4rem" size="xl" color={secondaryColor} thickness="3px" />
      )}
    </Flex>
  );
};

export default Messages;
