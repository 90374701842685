import React, { useState, useEffect } from "react";
import {
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import userApi from "services/api/user";
import messagesApi from "services/api/messages";

const ProfileHeader = ({ data, selfProfile }) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");

  const history = useHistory()

  const [canFollow, toggleFollow] = useState(data.is_following)

  const toggleFollowUser = async () => {
    let followHandler;
    if (!selfProfile) {
      if (canFollow) {
        await userApi.unfollowUser(data.id).then((response) => {
          if (response.success) followHandler = false;
        });
      } else {
        await userApi.followUser(data.id).then((response) => {
          if (response.success) followHandler = true;
        });
      }
    }
    toggleFollow(followHandler);
  };

  const handleConversation = async () => {
    await messagesApi.startConversation(data.id).then((response) => {
      if(response.success) {
        history.push({
          pathname: `/app/message/user/${response.conversation.id}`,
          state: {
            receiverId: data.id,
          },
        });
      }
    })
  }

  return (
    <Grid
      templateColumns="repeat(3, 1fr) .5fr"
      templateRows="repeat(3, 1fr)"
      gap="24px"
      p="24px"
      bgColor={primaryColor}
      borderRadius={{ sm: "10px", md: "20px" }}
    >
      <GridItem rowSpan={3}>
        <Box>
          <Image borderRadius="15" height="12rem" src={data.profile_pic} />
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={1}>
        <Box>
          <Text
            color={headerTextColor}
            fontWeight="semibold"
            fontSize={{ base: "16", md: 18, xl: "20" }}
          >
            {data.nickname}
          </Text>
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={3} colSpan={2}>
        <Box>
          <Text>{data.description}</Text>
        </Box>
      </GridItem>
      <GridItem colStart={2} rowStart={2}>
        <Box>
          <Text>{data.username}</Text>
        </Box>
      </GridItem>
      <GridItem colStart={3} rowStart={1}>
        <Box>
          {!!data.role && (
            <Text>
              {data.role === "artist"
                ? "Artista"
                : data.role === "user"
                ? "Usuario"
                : "Administrador"}
            </Text>
          )}
        </Box>
      </GridItem>
      <GridItem colStart={4} rowStart={3}>
        <Box>
          {data.allow_messages && !selfProfile && data.role === "artist" ? <Button onClick={handleConversation}>Chat</Button> : <></>}
        </Box>
      </GridItem>
      <GridItem colStart={4} rowStart={2}>
        <Box>
          {selfProfile || data.role !== "artist" ? (
            <></>
          ) : (
            <Button onClick={toggleFollowUser}>{canFollow ? "Dejar de seguir" : "Seguir"}</Button>
          )}
        </Box>
      </GridItem>
      <GridItem>
        <Box>
          <Text>{data.followers_count} seguidores</Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ProfileHeader;
