// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import userServices from "services/storage/user";
import authApi from "services/api/auth";
import notificationsApi from "services/api/notifications";

export default function HeaderLinks(props) {
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const history = useHistory();

  const [notifications, setNotifications] = useState([]);
  const [unseenNotifications, setUnseenNotifications] = useState(false);

  // Chakra Color Mode
  let navbarIcon = useColorModeValue("gray.500", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
    getNotifications();

    const interval = setInterval(() => {
      getNotifications();
      getUser();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const getNotifications = async () => {
    await notificationsApi.getNotifications().then((response) => {
      if (response.success) {
        if (
          !!response.notifications.length &&
          response.notifications[0].pivot.read_at === null
        ) {
          setUnseenNotifications(true);
        } else setUnseenNotifications(false);
        setNotifications(response.notifications.slice(0, 5));
      }
    });
  };

  const getUser = async () => {
    const data = await userServices.getUser();
    setUser(data);
  };

  const logout = async () => {
    await authApi.logout().then((response) => {
      console.log(response);
      if (response) history.push("/");
    });
  };

  const options = [
    {
      text: "Configuración",
      onClick: () => {},
    },
    {
      text: "Cerrar sesión",
      onClick: logout,
    },
  ];

  const getNotificationLink = (notification) => {
    switch (notification.item_type) {
      case "transaction":
        return `/app/transactions/${notification.entity_id}/following`;
      case "user":
        return `/app/user/${notification.entity_id}`;
      case "question":
        return `/app/sales/${notification.entity_id}/details`;
      case "artwork":
        return `/app/sales/${notification.entity_id}/details`;
      default:
        return "app/home";
    }
  };

  const viewNotification = async (notification) => {
    await notificationsApi.markNotificationAsRead(notification.id).then((response) => {
      console.log(response);
      history.push(getNotificationLink(notification))
    });
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Menu >
        <MenuButton px="4" position="relative">
          {unseenNotifications && (
            <Box
              m={0}
              p={0}
              backgroundColor={"#FF0000"}
              h=".5rem"
              w=".5rem"
              position="absolute"
              top=".15rem"
              right="1.1rem"
              borderRadius="50%"
            ></Box>
          )}
          <BellIcon
            color={unseenNotifications ? primaryColor : navbarIcon}
            w="25px"
            h="25px"
          />
        </MenuButton>
        <MenuList p="10px 8px" width="20rem">
          <Flex flexDirection="column">
            {notifications.length > 0 ? (
              notifications.map((notification) => {
                return (
                  <MenuItem
                    borderRadius="8px"
                    mb="8px"
                    onClick={() => viewNotification(notification)}
                  >
                    <ItemContent notification={notification} />
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem
                borderRadius="8px"
                mb="10px"
                cursor="unset"
                _hover={{ backgroundColor: "white" }}
                // width="20rem"
              >
                <Text>No hay notificaciones que mostrar.</Text>
              </MenuItem>
            )}
          </Flex>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton>
          <Flex>
            <Text display={{ sm: "none", md: "flex" }} alignItems="center">
              @{user.username}
            </Text>
            <Image
              src={user.profilePicture}
              alt="profile picture"
              width="3rem"
              borderRadius="full"
              ms="1em"
            />
          </Flex>
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            {!!options.length &&
              options.map((option, i) => {
                return (
                  <MenuItem key={i} borderRadius="8px" onClick={option.onClick}>
                    {option.text}
                  </MenuItem>
                );
              })}
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
