import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const login = async (data) => {
  const route = "/login";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  };

  return await sendRequest(route, request)
    .then(async (response) => {
      if (response.success) {
        localStorage.setItem(
          "data",
          JSON.stringify({ ...response.user, token: response.access_token })
        );
        return true;
      }
    })
    .catch((e) => {
      throw e;
    });
};

const signup = async (data) => {
  const route = "/signup";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  };
  return await sendRequest(route, request)
    .then(async (response) => {
      if (response.success) {
        localStorage.setItem(
          "data",
          JSON.stringify({ ...response.user, token: response.access_token })
        );
        return true;
      }
    })
    .catch((e) => {
      throw e;
    });
};

const logout = async () => {
  const route = "/logout";
  const user = JSON.parse(localStorage.getItem("data"));
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
      Accept: "application/json",
    },
  };
  return await sendRequest(route, request).then((response) => {
    console.log(response);
    if (response.success) {
      localStorage.removeItem("data");
      return true;
    } else {
      return false;
    }
  });
};

const fetchUser = async () => {
  const route = "/user";
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };

  return await sendRequest(route, request)
    .then((response) => {
      if (response.success) {
        user.updateUser({...response.user})
      }
    })
    .catch((e) => {
      throw e;
    });
};

export default {
  login,
  signup,
  logout,
  fetchUser,
};
