/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import userService from "services/storage/user";

import AuthLayout from "layouts/Auth.js";
import InkdexLayout from "layouts/Inkdex.js";
import AdminLayout from "layouts/Admin.js";

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <Route path={`/app`} component={InkdexLayout} />
      <Route path={`/admin`} component={AdminLayout} />
      <Redirect
        from={`/`}
        to={
          userService.isAdmin()
            ? "/admin/home"
            : userService.isAuth()
            ? "/app/home"
            : "auth/login"
        }
      />
    </Switch>
  </Router>,
  document.getElementById("root")
);
