import React, { useState } from "react";
import {
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useColorModeValue,
} from "@chakra-ui/react";

const EvidenceGrid = ({ evidence }) => {
  const helperTextColor = useColorModeValue("gray.400", "gray.400");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(0);
  return (
    <>
      <Text color={helperTextColor} fontWeight="500">
        Haga click en una imagen para ampliarla
      </Text>
      <Grid
        w="100%"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md:
            evidence.length >= 3
              ? "repeat(3, 1fr)"
              : `repeat(${evidence.length}, 1fr)`,
          lg:
            evidence.length >= 4
              ? "repeat(4, 1fr)"
              : `repeat(${evidence.length}, 1fr)`,
        }}
        gap="15px"
      >
        {evidence.map((image, i) => {
          return (
            <GridItem colSpan={1} display="flex" justifyContent="center">
              <Box
                w={"12rem"}
                h={"12rem"}
                role="button"
                onClick={() => {
                  setSelectedImage(i);
                  onOpen();
                }}
                overflow={"hidden"}
              >
                <Image src={image} alt="Dan Abramov" />
              </Box>
            </GridItem>
          );
        })}
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex justifyContent={"center"}>
              <Image src={evidence[selectedImage]} alt="Dan Abramov" />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="secondaryLight" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EvidenceGrid;
