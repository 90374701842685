import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Image,
  FormHelperText,
  CircularProgress,
} from "@chakra-ui/react";
import { MdCancel } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import EvidenceGrid from "../../Admin/ArtistRequests/components/EvidenceGrid";

import userServices from "services/storage/user";
import userApi from "services/api/user";
import salesApi from "services/api/sales";
import Swal from "sweetalert2";

const schema = yup.object({
  fileString: yup.string().required(),
});

const FollowSale = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const [user, setUser] = useState({});
  const [transaction, setTransaction] = useState({});
  const [images, setImages] = useState([]);
  const [urlImages, setUrlImages] = useState([]);
  const [isBuyer, handleIsBuyer] = useState(false);
  const [loadingSubmit, handleLoadingSubmit] = useState(false);
  const user = userServices.getRole();

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    handleIsBuyer(user === "user");
    await salesApi
      .getTransaction(props.match.params.id)
      .then((response) => {
        if (response.success) {
          setTransaction({
            ...response.transaction,
            payment_confirmation: JSON.parse(
              response.transaction.payment_confirmation
            ),
            artwork_confirmation: JSON.parse(
              response.transaction.artwork_confirmation
            ),
          });
        }
      })
      .catch();
  };

  const handleImageChange = (e) => {
    const fileList = e.target.files;
    if (fileList.length === 0) return;
    const imageArray = [];
    const newFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.startsWith("image/") && file.size <= 2 * 1024 * 1024) {
        const url = URL.createObjectURL(file);
        imageArray.push(url);
        newFiles.push(file);
      }
      if (imageArray.length >= 20) {
        break; // Break loop if limit is reached
      }
    }
    setValue("fileString", JSON.stringify(imageArray));
    setUrlImages(imageArray);
    setImages(newFiles);
  };

  const onSubmit = async () => {
    handleLoadingSubmit(true);
    console.log(images.length);
    const formData = new FormData();
    images.map((image) => {
      formData.append(
        user === "user" ? "payment_confirmations[]" : "sent_confirmations[]",
        image
      );
    });

    console.table([...formData]);

    await salesApi
      .updateTransaction(formData, props.match.params.id, user === "user")
      .then((response) => {
        if (response.success) {
          Swal.fire({
            title: "Transacción actualizada",
            icon: "success",
            text: `La transacción ha sido actualizada exitosamente.`,
            showConfirmButton: true,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#4fd1c5",
          });
          history.push("/app/transactions");
        } else {
          Swal.fire({
            title: "Algo salió mal",
            icon: "warning",
            text:
              "Ocurrio un problema al actualizar la transacción. Recargue la página y vuelva a intentarlo.",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            cancelButtonColor: "#4fd1c5",
          });
          handleLoadingSubmit(false);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDirection={"column"}
        pt={{ base: "120px", md: "75px" }}
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        minWidth={"26rem"}
      >
        <Flex
          flexDirection={"column"}
          wrap="wrap"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
        >
          {!!transaction && transaction.artwork_confirmation ? (
            <EvidenceGrid evidence={transaction.artwork_confirmation} />
          ) : (
            <>
              <Text>Comprobante de envio</Text>
              {user === "artist" && (
                <>
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <FormLabel
                      color="secondaryLight.500"
                      _hover={{ color: "secondaryLight.700" }}
                      id="customer_evidence"
                      role="button"
                      m="0"
                    >
                      <Box
                        border="1px dashed black"
                        width={"12rem"}
                        height={"12rem"}
                        borderColor={secondaryColor}
                        borderRadius={"10px"}
                        padding="10px"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text>{"Subir evidencia(s)"}</Text>
                      </Box>
                    </FormLabel>
                    <Input
                      type="file"
                      onChange={handleImageChange}
                      multiple
                      accept="image/*"
                      hidden
                    />
                    <Input {...register("fileString")} hidden />
                    <FormHelperText>
                      {isBuyer
                        ? "Sube una captura o foto de comprobante de tu pago"
                        : "Sube una captura o foto de comprobante de tu envio"}
                    </FormHelperText>
                    <Flex flexWrap="wrap">
                      {urlImages.map((image, index) => (
                        <Box key={index} p="2" maxW="200px">
                          <Image src={image} alt={`image_${index}`} />
                        </Box>
                      ))}
                    </Flex>
                  </FormControl>

                  <Box>
                    {!loadingSubmit ? (
                      <Button
                        type="submit"
                        colorScheme={"primaryLight"}
                        fontSize="10px"
                        color="white"
                        fontWeight="bold"
                        ms="4px"
                        w="10rem"
                        h="45"
                        mb="24px"
                      >
                        ENVIAR
                      </Button>
                    ) : (
                      <CircularProgress isIndeterminate color="primaryLight.500" />
                    )}
                  </Box>
                </>
              )}
            </>
          )}
          {!!transaction && transaction.payment_confirmation ? (
            <EvidenceGrid evidence={transaction.payment_confirmation} />
          ) : (
            <>
              <Text>Comprobante de compra</Text>
              {user === "user" && (
                <>
                  <FormControl
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <FormLabel
                      color="secondaryLight.500"
                      _hover={{ color: "secondaryLight.700" }}
                      id="customer_evidence"
                      role="button"
                      m="0"
                    >
                      <Box
                        border="1px dashed black"
                        width={"12rem"}
                        height={"12rem"}
                        borderColor={secondaryColor}
                        borderRadius={"10px"}
                        padding="10px"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text>{"Subir evidencia(s)"}</Text>
                      </Box>
                    </FormLabel>
                    <Input
                      type="file"
                      onChange={handleImageChange}
                      multiple
                      accept="image/*"
                      hidden
                    />
                    <Input {...register("fileString")} hidden />
                    <FormHelperText>
                      {isBuyer
                        ? "Sube una captura o foto de comprobante de tu pago"
                        : "Sube una captura o foto de comprobante de tu envio"}
                    </FormHelperText>
                    <Flex flexWrap="wrap">
                      {urlImages.map((image, index) => (
                        <Box key={index} p="2" maxW="200px">
                          <Image src={image} alt={`image_${index}`} />
                        </Box>
                      ))}
                    </Flex>
                  </FormControl>

                  <Box>
                    {!loadingSubmit ? (
                      <Button
                        type="submit"
                        colorScheme={"primaryLight"}
                        fontSize="10px"
                        color="white"
                        fontWeight="bold"
                        ms="4px"
                        w="10rem"
                        h="45"
                        mb="24px"
                      >
                        ENVIAR
                      </Button>
                    ) : (
                      <CircularProgress isIndeterminate color="primaryLight.500" />
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

export default FollowSale;
