import React, { useState, useEffect } from "react";
import Table from "./components/Table";
import { tablesTableData } from "variables/general";
import adminServices from "services/api/admin";
import { Box, Flex, CircularProgress } from "@chakra-ui/react";

const UserList = () => {
  const perPage = 25;
  const [loader, setLoader] = useState(true);
  const [userList, setUserList] = useState([]);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async (page = 1, search = "") => {
    const start = (page - 1) * perPage + 1;
    await adminServices
      .countUsers()
      .then(async (countResponse) => {
        if (countResponse.success) {
          const totalUsers = countResponse.count
          await adminServices.getUsers({ start, search }).then((response) => {
            if (response.success) {
              setUserList(response.users);
              const evaluateLastItem = perPage > totalUsers ? totalUsers : page === metadata.last_page ? totalUsers : page * perPage
              let _metadata = {
                current_page: page,
                from: start,
                last_page: Math.floor(totalUsers/perPage)+1,
                to: evaluateLastItem,
                total: totalUsers,
              };
              setMetadata({ ..._metadata });
            }
          });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleChangePage = async (page, searchParam = "") => {
    setLoader(true);
    setUserList([]);
    setMetadata({});
    await getUserList(page, searchParam);
  };

  const handleSearchByUser = async (searchParam) => {
    console.log(searchParam);
    setLoader(true);
    setUserList([]);
    setMetadata({});
    await getUserList(1, searchParam);
  };

  return (
    <Flex
      flexDirection="column"
      marginTop={{ base: "120px", md: "75px" }}
      direction="column"
      justifyContent="center"
      id="container"
    >
      <Flex height={"70vh"} p="0px">
        <Table
          title={"Lista de usuarios"}
          captions={["User", "Role", "Status", "Register date", "Actions"]}
          data={userList}
          loading={loader}
          paginationData={metadata}
          onChangePage={handleChangePage}
          onSearch={handleSearchByUser}
        />
      </Flex>
    </Flex>
  );
};

export default UserList;
