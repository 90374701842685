import baseUrl from "constants/Api";
import userData from "services/storage/user";

export const sendRequest = async (route, request, isFile = false) => {
    let response;
    try {
      response = await fetch(baseUrl + route, request);
      if (response.status === 401) {
        //if (response.status === 401 || response.status == 500 || response.status == 403) {
        console.log("Sesión expirada")
        userData.logout()
        return { success: false };
      }
    } catch (error) {
      return { success: false };
    }
    try {
      const data =
        isFile && response.status === 200
          ? await response.blob()
          : await response.json();
      return data;
    } catch (error) {
      return { success: false };
    }
  };
  