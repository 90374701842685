import {
    Flex,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Stack,
    Text,
    useColorModeValue,
    Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import salesApi from "services/api/sales";
import { FaEllipsisV } from "react-icons/fa";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import { TablePaginator } from "components/Tables/TablePaginator.js";
import userServices from "services/storage/user"
import CreateSale from "views/Inkdex/Sales/CreateSale.js"
import Swal from "sweetalert2";



const ListSale = () => {
    const [artworks, setArtworks] = useState([]);
    const [metaData, setMetaData] = useState({});
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const textColor = useColorModeValue("gray.600");
    const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
    const secondaryColor = useColorModeValue(
        "secondaryLight.500",
        "secondaryDark.500"
    );

    const perpage = 10;

    useEffect(() => {
        fetchArtworks();
    }, []);

    const fetchArtworks = async (page = 1) => {
        try {
            const start = (page - 1) * perpage + 1
            const user = userServices.getUser()
            await salesApi.salesCount(user.id).then(async (countResponse) => {
                if (countResponse.success && countResponse.count > 0) {
                    const totalSales = countResponse.count
                    const response = await salesApi.listSale(user.id);
                    if (response.success) {
                        setArtworks(response.artworks);
                        let _metaData = {
                            current_page: page,
                            from: start,
                            last_page: Math.floor(totalSales / perpage) + 1,
                            to: page === metaData.last_page ? totalSales : page * perpage,
                            total: totalSales
                        }
                        setMetaData({ ..._metaData });
                        setLoading(false);
                    }
                }
            })
        } catch (error) {
            console.error("Error fetching artworks:", error);
        }
    };
    const actions = [
        {
            id: "details", title: "Ver detalles", action: (artwork) => {
                history.push(`/app/sales/${artwork.id}/details`)
            }
        },
        {
            id: "delete", title: "Eliminar", action: (artwork) => {
                deleteSale(artwork)
            }
        }
    ];

    const deleteSale = async (artwork) => {
        if(artwork.status === "Apartado" ) {
            Swal.fire({
                title: "Algo salió mal",
                icon: "warning",
                text:
                  "No puedes eliminar una obra en proceso de venta.",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Cerrar",
                cancelButtonColor: "#4fd1c5",
              })
              return
        } else {
            await salesApi.deleteSale(artwork.id).then((response) => {
                if(response.success) history.go(0);
            })
        }
    }

    const handleChangePage = async (page) => {
        setArtworks([])
        setMetaData({})
        await fetchArtworks(page)
    };

    return (
        <Flex
            flexDirection="column"
            marginTop={{ base: "120px", md: "75px" }}
            direction="column"
            justifyContent="center"
            id="container"
        >
            <Flex height={"70vh"} p="0px">
                <Card
                    overflowX={{ sm: "scroll", xl: "hidden" }}
                    overflowY="scroll"
                    padding="0"
                >

                    <CardHeader p="6px 22px 22px 22px" bg={secondaryColor}>
                        <Stack spacing={4} w="100%">
                            <Text fontSize="xl" color={textColor} fontWeight="bold">
                                Mis ventas
                            </Text>
                        </Stack>
                        <Button onClick={(() => history.push("/app/sales/create"))}>Nuevo</Button>
                    </CardHeader>
                    <CardBody p="0px 22px 22px 22px">
                        <Table title={"Artworks Table"} loading={loading}>
                            <Thead>
                                <Tr>
                                    <Th>Titulo</Th>
                                    <Th>Descripción</Th>
                                    <Th>Precio</Th>
                                    <Th>Estatus</Th>
                                    <Th>Fecha</Th>
                                    <Th>Imagen</Th>
                                    <Th>Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {artworks.map((artwork) => (
                                    <Tr key={artwork.id}>
                                        <Td>{artwork.title}</Td>
                                        <Td>{artwork.description}</Td>
                                        <Td>{artwork.price}</Td>
                                        <Td>{artwork.status}</Td>
                                        <Td>{artwork.created_at}</Td>
                                        <Td>
                                            {JSON.parse(artwork.image_url).map((url, index) => (
                                                <img key={index} src={url} alt={`Artwork ${index}`} style={{ maxWidth: "100px" }} />
                                            ))}
                                        </Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton
                                                    as={IconButton}
                                                    aria-label="Options"
                                                    icon={<FaEllipsisV />}
                                                    color={textColor}
                                                    variant="outline"
                                                />
                                                <MenuList>
                                                    {actions.map((action, key) => {
                                                        return (
                                                            <MenuItem key={key} onClick={() => action.action(artwork)} textColor={textColor}>
                                                                {action.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            <TablePaginator paginationData={metaData} onChangePage={handleChangePage} />
                        </Table>
                    </CardBody>
                </Card>
            </Flex>
        </Flex>
    );
};

export default ListSale;
