/*eslint-disable*/
// chakra imports
import { Box, useColorModeValue } from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import ChatbarContent from "./ChatbarContent";
import userData from "services/storage/user";

// FUNCTIONS

function Chatbar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { logoText, routes, sidebarVariant } = props;
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = primaryColor;
    sidebarRadius = "16px";
    sidebarMargins = "100px 16px 0px 16px";
  }

  const [user, setUser] = useState({})

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    const _user = await userData.getUser()
    setUser(_user)
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box
        display={{ sm: "none", xl: "block" }}
        position="fixed"
        right="0"
        bottom="0"
        // zIndex="10"
      >
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 121px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <ChatbarContent
            logoText={"CHAT"}
            display="none"
            icon="ChatIcon"
            enabledMessages={user.allow_messages}
            sidebarVariant={sidebarVariant}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Chatbar;
