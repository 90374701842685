import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const getMessages = async () => {
    const route = `/conversations?start=1&noi=10`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getAllMessages = async () => {
    const route = `/conversations`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getConversation = async (conversationId) => {
    const route = `/conversations/${conversationId}/messages?noi=100`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const sendMessage = async (data, conversationId) => {
    const route = `/conversations/${conversationId}/send-message`;
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: data
    };
    return await sendRequest(route, request);
}

const startConversation = async (user_id) => {
    const formData = new FormData();
    formData.append("user_b_id", user_id)
    const route = `/conversations`;
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData
    };
    return await sendRequest(route, request);    
}

export default {
    getMessages,
    getAllMessages,
    getConversation,
    sendMessage,
    startConversation
}