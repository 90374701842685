// Chakra imports
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Switch,
    Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Select from 'react-select';
import salesApi from "services/api/sales"
import { useForm, SubmitHandler, register } from "react-hook-form"
import { Outlet } from "react-router-dom";
//import Table from "./components/Table";
//import { tablesTableData } from "variables/general";

const CreateSale = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [qnaEnabled, setQnaEnabled] = useState(false); // Estado inicial del switch

    const {
        register,
        errors,
        handleSubmit,
        watch,
    } = useForm();

    // Handler para actualizar las opciones seleccionadas
    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };


    // Handler para manejar la selección de archivos
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Filtrar solo las imágenes
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        setSelectedFiles(imageFiles);
    };

    // Handler para cambiar el estado del switch
    const handleSwitchChange = () => {
        setQnaEnabled(!qnaEnabled); // Cambia el estado del switch
    };

    const onSubmit = async (data) => {
        console.log(data)
        const formData = new FormData()
        formData.append("title", data.title)
        formData.append("description", data.description)
        formData.append("price", data.price)
        formData.append("qna_enabled", qnaEnabled ? 1 : 0) // Envía el estado del switch
        selectedFiles.map((file) => {
            formData.append("image_files[]", file)
        })
        selectedOptions.forEach(option => {
            formData.append("tags[]", option.value); // Suponiendo que el valor de la opción es lo que deseas enviar a la API
        });

        console.table([...formData])
        await salesApi.createSale(formData).then((response) => {
            if (response.success) console.log("Accion realizada con exito")
        }).catch((e) => {
            console.log("Error:", e)
        })
    }
    console.log(errors)

    return (
        <Flex
            flexDirection={{ base: "column", md: "row" }}
            pt={{ base: "120px", md: "75px" }}
            wrap="wrap"
            justifyContent="center"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="title" m={0} p={0}>
                <FormLabel>Título</FormLabel>
                <Input {...register("title")} type="text" placeholder="Escribe un título para la publicación" />
                </FormControl>
                
                <FormControl id="description" m={0} p={0}>
                <FormLabel>Descripción</FormLabel>
                <Input {...register("description")} type="text" placeholder="Escribe una descripción para la publicación" />
                </FormControl>

                <FormControl id="price">
                <FormLabel>Precio</FormLabel>
                <Input {...register("price")} type="number" min={0} max={10000000}/>
                </FormControl>
                
                
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                        Permitir preguntas para esta publicacion
                    </FormLabel>
                    {/* Aquí maneja el cambio del switch */}
                    <Switch id='email-alerts' onChange={handleSwitchChange} isChecked={qnaEnabled} />
                </FormControl>

                {/* Multiselect */}
                <FormControl>
                    <FormLabel htmlFor="select-multiple">Selecciona múltiples opciones:</FormLabel>
                    <Select
                        id="select-multiple"
                        options={[
                            { value: 1, label: 'Abstract' },
                            { value: 2, label: 'Landscape' },
                            { value: 3, label: 'Portrait' },
                            { value: 4, label: 'Surreal' },
                            { value: 5, label: 'Nature' },
                            { value: 6, label: 'Digital Art' },
                            { value: 7, label: 'Oil Painting' },
                            { value: 8, label: 'Watercolor' },
                            { value: 9, label: 'Photography' }
                        ]}
                        isMulti
                        value={selectedOptions}
                        onChange={handleSelectChange}
                    />
                </FormControl>

                {/* Input para cargar imágenes */}
                <FormControl>
                    <FormLabel htmlFor="image-upload">Subir imágenes (solo imágenes permitidas):</FormLabel>
                    <Input
                        id="image-upload"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </FormControl>

                <Button colorScheme="primaryLight" type="submit" className="btn btn-primary">Publicar/vender</Button>
            </form>
        </Flex>
    )
}

export default CreateSale;
