export const userData = {
    id: 1,
    token: "ola",
    role: "artist",
    username: "Mechanic Taco",
    nickname: "Nando Nunez",
    email: "ednandonunez@gmail.com",
    profile_pic: "https://get.wallhere.com/photo/Seraphine-League-of-Legends-League-of-Legends-KDA-Seraphine-women-purple-hair-blue-eyes-kda-fan-art-digital-art-video-game-girls-video-games-cats-lying-on-side-anime-girls-sweater-books-2093291.jpg",
    description: "Amateur artist. Coms open",
    allow_messages: true
}

export const publicacion = [
    {
    "score": 1.4,
    "discover": true,
    "artwork": {
        "id": 1,
        "user_id": 2,
        "title": "Artwork 1",
        "description": "Description for Artwork 1",
        "image_url": ["https://www.misgafasdepasta.com/wp-content/uploads/2018/03/fotos-de-stock-mas-raras-mis-gafas-de-pasta02.jpg"],
        "price": "100.00",
        "status": "listed",
        "tags": [
            {
                "id": 1,
                "name": "Abstract",
                "created_at": "2023-09-04T01:59:00.000000Z",
                "updated_at": "2023-09-04T01:59:00.000000Z",
                "deleted_at": null,
                "pivot": {
                    "artwork_id": 1,
                    "tag_id": 1,
                    "created_at": "2023-09-04T01:59:00.000000Z",
                    "updated_at": "2023-09-04T01:59:00.000000Z"
                }
            },
            {
                "id": 2,
                "name": "Landscape",
                "created_at": "2023-09-04T01:59:00.000000Z",
                "updated_at": "2023-09-04T01:59:00.000000Z",
                "deleted_at": null,
                "pivot": {
                    "artwork_id": 1,
                    "tag_id": 2,
                    "created_at": "2023-09-04T01:59:00.000000Z",
                    "updated_at": "2023-09-04T01:59:00.000000Z"
                }
            }
            ]
        }
    },
    {
        "score": 1.4,
        "discover": true,
        "artwork": {
            "id": 2,
            "user_id": 3,
            "title": "Artwork 2",
            "description": "Description test 2",
            "image_url": ["https://www.misgafasdepasta.com/wp-content/uploads/2018/03/fotos-de-stock-mas-raras-mis-gafas-de-pasta02.jpg"],
            "price": "200.00",
            "status": "listed",
            "tags": [
                {
                    "id": 1,
                    "name": "Abstract",
                    "created_at": "2023-09-04T01:59:00.000000Z",
                    "updated_at": "2023-09-04T01:59:00.000000Z",
                    "deleted_at": null,
                    "pivot": {
                        "artwork_id": 1,
                        "tag_id": 1,
                        "created_at": "2023-09-04T01:59:00.000000Z",
                        "updated_at": "2023-09-04T01:59:00.000000Z"
                    }
                },
                {
                    "id": 2,
                    "name": "Landscape",
                    "created_at": "2023-09-04T01:59:00.000000Z",
                    "updated_at": "2023-09-04T01:59:00.000000Z",
                    "deleted_at": null,
                    "pivot": {
                        "artwork_id": 1,
                        "tag_id": 2,
                        "created_at": "2023-09-04T01:59:00.000000Z",
                        "updated_at": "2023-09-04T01:59:00.000000Z"
                    }
                }
                ]
            }
        },
        {
            "score": 1.4,
            "discover": true,
            "artwork": {
                "id": 3,
                "user_id": 4,
                "title": "Artwork 3",
                "description": "Description for test 3",
                "image_url": ["https://www.misgafasdepasta.com/wp-content/uploads/2018/03/fotos-de-stock-mas-raras-mis-gafas-de-pasta02.jpg"],
                "price": "300.00",
                "status": "listed",
                "tags": [
                    {
                        "id": 1,
                        "name": "Abstract",
                        "created_at": "2023-09-04T01:59:00.000000Z",
                        "updated_at": "2023-09-04T01:59:00.000000Z",
                        "deleted_at": null,
                        "pivot": {
                            "artwork_id": 1,
                            "tag_id": 1,
                            "created_at": "2023-09-04T01:59:00.000000Z",
                            "updated_at": "2023-09-04T01:59:00.000000Z"
                        }
                    },
                    {
                        "id": 2,
                        "name": "Landscape",
                        "created_at": "2023-09-04T01:59:00.000000Z",
                        "updated_at": "2023-09-04T01:59:00.000000Z",
                        "deleted_at": null,
                        "pivot": {
                            "artwork_id": 1,
                            "tag_id": 2,
                            "created_at": "2023-09-04T01:59:00.000000Z",
                            "updated_at": "2023-09-04T01:59:00.000000Z"
                        }
                    }
                    ]
                }
            }
]