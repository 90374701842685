import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
} from "@chakra-ui/react";
import React from "react";

import { FaEllipsisV } from "react-icons/fa";

function TableRowUsers(props) {
  const { clientName, artistName, artwork, status, date, id, showBuyer, showSeller, onDetails } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const colorStatus = useColorModeValue("white", "gray.400");

  const evaluateStatus = (evaluationOutputs) => {
    if (status === "En proceso") return evaluationOutputs[0];
    else if (status === "Completado" || status === "Envios completados")
      return evaluationOutputs[1];
    else if (status === "Cancelado") return evaluationOutputs[2];
    else return evaluationOutputs[3];
  };

  const actions = [
    { id: "details", title: status === "Completado" ? "Ver detalles" : "Dar seguimiento", action: onDetails },
  ];

  return (
    <Tr>
      <Td pl="0px">
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          // minWidth="100%"
        >
          {id}
        </Text>
      </Td>

      {showBuyer && (
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {clientName}
          </Text>
        </Td>
      )}

      {showSeller && (
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {artistName}
          </Text>
        </Td>
      )}
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {artwork}
        </Text>
      </Td>
      <Td>
        <Badge
          bg={evaluateStatus([
            warningStatus,
            successStatus,
            dangerStatus,
            noStatus,
          ])}
          color={status ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>
      <Td>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisV />}
            color={textColor}
            variant="outline"
          />
          <MenuList>
            {actions.map((action, key) => {
              return (
                <MenuItem
                  key={key}
                  onClick={() => action.action(id)}
                  textColor={textColor}
                >
                  {action.title}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TableRowUsers;
