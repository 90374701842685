import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		gray: {
			700: '#1f2733'
		},
		primaryLight: {
			100: "#A7CAE6",
			500: "#6ca6e1",
			600: "#4D7FB5",
			700: "#2A4A6F",
		},
		secondaryLight: {
			100: "#6AE3DC",
			500: "#4fd1c5",
			600: "#34AFA9",
			700: "#1D7D71",
		},
		primaryDark: {
			100: "#324E91",
			500: "#162a6f",
			600: "#0D1F59",
			700: "#071026",
		},
		secondaryDark: {
			100: "#3FDAC8",
			500: "#1dd3b0",
			600: "#179981",
			700: "#0F7A6C",
		},
		danger: {
			100: "#FF6E83",
			500: "#FF1744",
			600: "#D40015",
			700: "#A10010",
		}, 
		warning: {
			100: "#FFF176",
			400: "#FFEB3B",
			500: "#DBA316",
			600: "#BF8B0A",
			700: "#9C7003",
		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode('gray.50', 'gray.800')(props),
				fontFamily: "'Roboto', sans-serif"
			},
			html: {
				fontFamily: "'Roboto', sans-serif"
			}
		})
	}
};
