import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import user from "services/storage/user";
import userApi from "services/api/user";

import HighlightPost from "./HighlightPost";
import SocialCard from "components/SocialCard";

const PostList = (props) => {
  const { selfProfile, user } = props;
  const [userPosts, setUserPosts] = useState([]);
  const isArtist = user.role === "artist";

  useEffect(() => {
    if(!!user && !!user.id) getUserPosts();
  }, [user]);

  const getUserPosts = async () => {
    await userApi.getUserPosts(user.id).then((response) => {
      if (response.success) {
        setUserPosts(response.posts);
      }
    });
  };

  console.log(userPosts)

  return (
    <Flex flexDirection="column" pt={{ base: "20px", md: "0" }}>
      {userPosts?.length > 0 &&
        userPosts.map((post, i) => <SocialCard key={i} data={{ item: {...post}}}  isSale={false} canHighlight={false} type="post"/>)}
    </Flex>
  );
};

export default PostList;
