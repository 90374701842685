import React, { useState, useEffect } from "react";
import userApi from "services/api/user";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  Input,
  FormControl,
  FormLabel,
  Spinner,
  useColorModeValue,
  Textarea,
  Button,
  Icon,
} from "@chakra-ui/react";
import { MdOutlineBorderColor } from "react-icons/md";

const schema = yup.object().shape({
  password: yup.string().required("Este campo es requerido"),
  newPassword: yup
    .string()
    .required("Este campo es requerido")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  // newPasswordConf: yup
  //   .string()
  //   .required("Este campo es requerido")
  //   .oneOf([yup.ref("newPassword"), null], "Las contraseñas deben coincidir")
  //   .min(8, "La contraseña debe tener al menos 8 caracteres"),
});

const ChangePass = () => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600");
  const optionHoveredText = useColorModeValue("gray.200", "gray.200");
  const optionText = useColorModeValue("gray.600", "gray.600");
  const helperTextColor = useColorModeValue("gray.400", "gray.400");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue("secondaryLight.500", "secondaryDark.500");

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreview(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
    setFile(selectedFile);
  };

  console.log(errors);

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("current_password", values.password);
    formData.append("new_password", values.newPassword);
    console.log([...formData]);
    const data = {
      current_password: values.password,
      new_password: values.newPassword
    }
    userApi.updatePassword(formData);
  };

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      pt={{ base: "120px", md: "75px" }}
      minHeight="80vh"
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Flex direction="column" w={{ base: "100%", md: "45%" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb="24px" id="password">
            <FormLabel fontSize="sm" fontWeight="normal">
              Contraseña actual
            </FormLabel>
            <Input
              fontSize="sm"
              borderRadius="15px"
              type="text"
              placeholder="Contraseña actual"
              size="lg"
              // required
              {...register("password")}
            />
          </FormControl>
          <FormControl mb="24px" id="newPassword">
            <FormLabel fontSize="sm" fontWeight="normal">
              Nueva contraseña
            </FormLabel>
            <Input
              fontSize="sm"
              borderRadius="15px"
              type="text"
              placeholder="Nueva contraseña"
              size="lg"
              // required
              {...register("newPassword")}
            />
          </FormControl>
          {/* <FormControl mb="24px" id="newPasswordConf">
            <FormLabel fontSize="sm" fontWeight="normal">
              Confirmar nueva contraseña
            </FormLabel>
            <Input
              fontSize="sm"
              borderRadius="15px"
              rows={6}
              placeholder="Confirmar nueva contraseña"
              size="lg"
              // required
              {...register("newPasswordConf")}
            />
          </FormControl> */}
          <Button
            fontSize="16px"
            type="submit"
            // bg={primaryColor}
            colorScheme="primaryLight"
            w="100%"
            h="45"
            mb="20px"
            color="white"
            mt="20px"
          >
            GUARDAR
          </Button>
        </form>
      </Flex>
    </Flex>
  );
};

export default ChangePass;
