import React, { useState } from "react";
import {
  Flex,
  Td,
  Text,
  Tr,
  Tfoot,
  Button,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

export const TablePaginator = ({ paginationData, onChangePage }) => {
  const textColor = useColorModeValue("white");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const {
    current_page,
    from,
    last_page,
    to,
    total,
  } = paginationData;

  const [countByElements, setCountByElements] = useState(true);

  const handleCounterType = () => {
    setCountByElements(!countByElements);
  };

  const canGoFurther = current_page < last_page;
  const canGoFewer = current_page > 1;
  return (
    <Tfoot>
      <Tr>
        <Td colSpan="100%">
          <Flex w="100%" justify="end">
            <Box color="#c9c9c9" cursor="default" onClick={handleCounterType}>
              {countByElements
                ? "Registros del " + from + " al " + to + " de " + total
                : "Página " + current_page + " de " + last_page}
            </Box>
          </Flex>
          <Flex
            my=".8rem"
            pl="0px"
            color="gray.400"
            justifyContent="space-between"
          >
            <Flex direction="column" alignItems="center">
              <Button
                disabled={!canGoFewer}
                py={3}
                variant="link"
                colorScheme="secondaryLight"
                onClick={() => onChangePage(current_page - 1)}
              >
                Anterior
              </Button>
              <Button
                disabled={!canGoFewer}
                py={3}
                variant="link"
                colorScheme="secondaryLight"
                onClick={() => onChangePage(1)}
              >
                Primera
              </Button>
            </Flex>
            <Flex justifyContent="center" direction="column">
              <Flex
                backgroundColor={secondaryColor}
                color={textColor}
                w="40px"
                h="40px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontSize={20}>{current_page}</Text>
              </Flex>
            </Flex>
            <Flex direction="column" alignItems="end">
              <Button
                disabled={!canGoFurther}
                py={3}
                variant="link"
                colorScheme="secondaryLight"
                onClick={() => onChangePage(current_page + 1)}
              >
                Siguiente
              </Button>
              <Button
                disabled={!canGoFurther}
                py={3}
                variant="link"
                colorScheme="secondaryLight"
                onClick={() => onChangePage(last_page)}
              >
                Última
              </Button>
            </Flex>
          </Flex>
        </Td>
      </Tr>
    </Tfoot>
  );
};
