import React, { useState, useEffect } from "react";
import userApi from "services/api/user";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  Input,
  FormControl,
  FormLabel,
  Spinner,
  useColorModeValue,
  Textarea,
  Button,
  Icon,
  CircularProgress
} from "@chakra-ui/react";
import { MdOutlineBorderColor } from "react-icons/md";
import Swal from "sweetalert2";

const schema = yup.object({
});

const UpdateUser = () => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600");
  const optionHoveredText = useColorModeValue("gray.200", "gray.200");
  const optionText = useColorModeValue("gray.600", "gray.600");
  const helperTextColor = useColorModeValue("gray.400", "gray.400");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue("secondaryLight.500", "secondaryDark.500");

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const [user, setUser] = useState({});
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loadingSubmit, handleLoadingSubmit] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreview(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
    setFile(selectedFile);
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await userApi.getUser().then((response) => {
      console.log(response);
      setUser(response.user);
      setPreview(response.user.profile_pic);
      setValue("description", response.user.description);
      setValue("nickname", response.user.nickname);
      setValue("username", response.user.username);
    });
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    handleLoadingSubmit(true)
    formData.append("description", values.description);
    formData.append("nickname", values.nickname);
    if(!!values.username) formData.append("username", values.username);
    if(!!file) formData.append("profile_pic", file);
    console.log(values);
    await userApi
      .updateUser(formData)
      .then((response) => {
        
        if(response.success){
          Swal.fire({
            title: "Transacción actualizada",
            icon: "success",
            text:
            `Perfil actualizado exitosamente.`,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: "#4fd1c5",
          });
          history.push('/app/settings');
        } else {
          Swal.fire({
            title: "Algo salió mal",
            icon: "warning",
            text:
              "Ocurrio un problema al actualizar su perfil. Recargue la página y vuelva a intentarlo.",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            cancelButtonColor: "#4fd1c5",
          });
          handleLoadingSubmit(false)
        }
      });
  };

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      pt={{ base: "120px", md: "75px" }}
      minHeight="80vh"
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {Object.keys(user).length ? (
        <Flex direction="column" w={{ base: "100%", md: "45%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex mb={4} justifyContent="center">
              <Input
                type="file"
                id="fileInput"
                hidden
                {...register("profile_pic")}
                onChange={handleFileChange}
              />
              {preview && (
                <label htmlFor="fileInput">
                  <Box
                    position="relative"
                    borderRadius="full"
                    borderStyle="solid"
                    borderWidth="2px"
                    borderColor={secondaryColor}
                    role="button"
                    mb={4}
                  >
                    <Image
                      src={preview}
                      alt="Preview"
                      boxSize="14rem"
                      objectFit="cover"
                      borderRadius="inherit"
                    />
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      background="rgba(0, 0, 0, 0.5)"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="inherit"
                      opacity="0"
                      transition="opacity 0.3s"
                      _hover={{ opacity: 1 }}
                    >
                      <Icon
                        as={MdOutlineBorderColor}
                        color={secondaryColor}
                        boxSize="2rem"
                      />
                    </Box>
                  </Box>
                </label>
              )}
            </Flex>
            <FormControl mb="24px" id="username">
              <FormLabel fontSize="sm" fontWeight="normal">
                Username
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="text"
                placeholder="Username"
                size="lg"
                // required
                {...register("username")}
              />
            </FormControl>
            <FormControl mb="24px" id="nickname">
              <FormLabel fontSize="sm" fontWeight="normal">
                Nickname
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="text"
                placeholder="Nickname"
                size="lg"
                // required
                {...register("nickname")}
              />
            </FormControl>
            <FormControl mb="24px" id="description">
              <FormLabel fontSize="sm" fontWeight="normal">
                Descripción
              </FormLabel>
              <Textarea
                fontSize="sm"
                borderRadius="15px"
                rows={6}
                placeholder="Descripción"
                size="lg"
                // required
                {...register("description")}
              />
            </FormControl>
            {!loadingSubmit ? 
            <Button
              fontSize="16px"
              type="submit"
              colorScheme="primaryLight"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
            >
              GUARDAR
            </Button>
            :
            <CircularProgress isIndeterminate color="primaryLight.500" />
            }
          </form>
        </Flex>
      ) : (
        <Spinner mt="4rem" size="xl" color={secondaryColor} thickness="3px" />
      )}
    </Flex>
  );
};

export default UpdateUser;
