// chakra imports
import { Avatar, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import { BellIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";
import React from "react";

export function ItemContent(props) {
  const navbarIcon = useColorModeValue("gray.500", "gray.200");
  const notificationColor = useColorModeValue("gray.700", "white");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");

  const { title, message, pivot} = props.notification
  return (
    <>
      <BellIcon
        borderRadius="12px"
        me="16px"
        w="1.75rem"
        h="1.75rem"
        color={!!pivot.read_at ? "gray.300" : primaryColor}
      />
      <Flex flexDirection="column">
          <Text fontWeight="bold" fontSize="14px" as="span">
            {title}
          </Text>
        <Text fontSize="12px" mb="5px" color={notificationColor}>
          {message}
        </Text>
      </Flex>
    </>
  );
}
