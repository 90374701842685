import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const createSale = async (data) => {
  const route = "/create-artwork";
  const token = user.getToken();
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  return await sendRequest(route, request);
};
const listSale = async (id) => {
  const route = `/user/${id}/artworks`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
};

const deleteSale = async (id) => {
  const route = `/user/artworks/${id}`;
  const token = user.getToken();
  const request = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
};

const detail = async (id) => {
  const route = `/artworks/${id}`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
};
const salesCount = async (id) => {
  const route = `/user/${id}/artworks/count`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
};

const getTransaction = async (id) => {
  const route = `/transaction/${id}`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
};

const updatePaymentConf = async (data, id) => {
  const route = `/payment/${id}`;
  const token = user.getToken();
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  return await sendRequest(route, request);
};

const updateArtSentConf = async (data, id) => {
  const route = `/mailing/${id}`;
  const token = user.getToken();
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  return await sendRequest(route, request);
};

const updateTransaction = async (data, id, isBuyer) => {
  const route = `/${isBuyer ? "payment" : "mailing"}/${id}`;

  const token = user.getToken();
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  return await sendRequest(route, request);
};

const countTransactions = async () => {
  const route = `/transactions/count`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
}

const getTransactions = async (start) => {
    const route = `/transactions?start=${start}&noi=25`;
    const token = user.getToken();
    const request = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await sendRequest(route, request);
}

const buyArtwork = async (id) => {
    const route = `/buy`;
    const formData = new FormData();
    formData.append("artwork_id", id)
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body:formData
    };
    return await sendRequest(route, request);
}

const countQnA = async (id) => {
  const route = `/artworks/${id}/questions/count`;
  const token = user.getToken();
  const request = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
}

const getQnA = async (id, noi) => {
    const route = `/artworks/${id}/questions?noi=${noi}`;
    const token = user.getToken();
    const request = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await sendRequest(route, request);
}

const createQuestion = async (id, data) => {
  const route = `/artworks/${id}/questions`;
  const token = user.getToken();
  const request = {
      method: "POST",
      headers: {
          Authorization: `Bearer ${token}`,
      },
      body: data
  };
  return await sendRequest(route, request);
}

const answerQuestion = async (id, data) => {
  const route = `/artworks/questions/${id}/answer`;
  const token = user.getToken();
  const request = {
      method: "POST",
      headers: {
          Authorization: `Bearer ${token}`,
      },
      body: data
  };
  return await sendRequest(route, request);
}

const highlight = async (id, highlight) => {
  const data = new FormData();
  data.append("highlighted", highlight ? 1 : 0)
  // data.append("_method", "PUT")
  const route = `/artworks/${id}`;
  const token = user.getToken();
  const request = {
      method: "POST",
      headers: {
          Authorization: `Bearer ${token}`,
      },
      body: data
  };
  return await sendRequest(route, request);
}

export default {
  createSale,
  listSale,
  deleteSale,
  detail,
  salesCount,
  getTransaction,
  updateTransaction,
  buyArtwork,
  getTransactions,
  countTransactions,
  countQnA,
  getQnA,
  createQuestion,
  answerQuestion,
  highlight,
};
