import { sendRequest } from "utils/utils";
import user from "services/storage/user";

const getUsers = async ({start, search}) => {
    let route = `/list-users?start=${start}&noi=25`;
    route = !!search ? route + `&search=${search}` : route;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const countUsers = async () => {
    let route = `/list-users/count`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const getTickets = async ({page, search}) => {
    // let route = "/tickets";
    let route = `/tickets?page=${page}`;
    route = !!search ? route + `?user_id=${search}` : route;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const countTickets = async () => {
    let route = `/tickets/count`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);

}

const getTicket = async (ticketId) => {
    // let route = "/tickets";
    let route = `/tickets/${ticketId}`;
    const token = user.getToken();
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const resolveTicket = async (data, id) => {
    const route = `/tickets/${id}`;
    const token = user.getToken();
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: data
    };
    return await sendRequest(route, request);
}

const updateTransaction = async (data, id) => {
  const route = `/received/${id}`;

  const token = user.getToken();
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  return await sendRequest(route, request);
};

const deleteUser = async (id) => {
    const route = `/delete-user`;
  
    const token = user.getToken();
    const request = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {user_id: id}
    };
    return await sendRequest(route, request);
}

export default {
    getUsers,
    countUsers,
    getTickets,
    countTickets,
    resolveTicket,
    getTicket,
    updateTransaction,
    deleteUser
}