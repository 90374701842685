import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  useColorModeValue,
  Button,
  Spinner,
  Icon,
  Center,
  IconButton,
  ButtonGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  MdPassword,
  MdPalette,
  MdCommentsDisabled,
  MdComment,
  MdOutlineBorderColor,
  MdCancel,
} from "react-icons/md";

import userApi from "services/api/user";

const SettingSquare = (props) => {
  return (
    <Box onClick={props.action} textAlign="center" {...props.style}>
      <Flex
        direction={{ base: "row", md: "column" }}
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Icon
          as={props.icon}
          boxSize={{ base: 8, md: 14 }}
          me={{ base: "2rem", md: 0 }}
          color={props.iconColor || "inherit"}
        />
        <Text>{props.title}</Text>
      </Flex>
    </Box>
  );
};

const Settings = (props) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600");
  const optionHoveredText = useColorModeValue("gray.200", "gray.200");
  const optionText = useColorModeValue("gray.600", "gray.600");
  const helperTextColor = useColorModeValue("gray.400", "gray.400");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const optionProps = {
    h: { base: "8rem", md: "12rem", xl: "14rem" },
    minWidth: "14rem",
    m: "2rem",
    _hover: { backgroundColor: secondaryColor, textColor: optionHoveredText },
    textColor: secondaryColor,
    fontWeight: 600,
    role: "button",
    border: "5px solid black",
    borderColor: secondaryColor,
    borderRadius: { sm: "10px", md: "20px" },
  };

  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState({});

  const settingOptions = [
    {
      title: "Editar perfil",
      icon: MdOutlineBorderColor,
      action: () => history.push("settings/edit-profile"),
    },
    {
      title: "Cambiar contraseña",
      icon: MdPassword,
      action: () => history.push("settings/change-password"),
    },
    {
      title: "Quiero ser artista",
      icon: MdPalette,
      action: () => history.push("settings/inkdex-me"),
    },
    {
      title: !!user.allow_messages
        ? "Deshabilitar mensajes"
        : "Habilitar mensajes",
      icon: !!user.allow_messages ? MdCommentsDisabled : MdComment,
      action: async () => {
        await userApi.toggleAllowMessages().then((response) => {
          if (response.success) {
            console.log(response.user.allow_messages);
            setUser(response.user);
            history.go(0);
          }
        });
      },
    },
    {
      title: "Eliminar mi cuenta",
      icon: MdCancel,
      iconColor: dangerColor,
      action: onOpen,
      delete: true,
    },
  ];

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await userApi.getUser().then((response) => {
      console.log(response);
      setUser(response.user);
    });
  };

  const deleteUser = async () => {
    await userApi.deleteUser().then((response) => {
      if (response.success) {
        onClose();
        history.push("/");
      }
    });
  };

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      pt={{ base: "120px", md: "75px" }}
      wrap="wrap"
      justifyContent="center"
    >
      {Object.keys(user).length ? (
        <>
          {settingOptions.map((option, i) => {
            if(option.title === "Quiero ser artista" && user.role === "artist") return 
            return <SettingSquare key={i} {...option} style={optionProps} />;
          })}
        </>
      ) : (
        <Spinner mt="4rem" size="xl" color={secondaryColor} thickness="3px" />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={dangerColor}>¡ESPERA!</ModalHeader>
          <ModalBody>
            <Text fontSize="lg" fontWeight="500">
              ¿Estas seguro que deseas eliminar tu cuenta?
            </Text>
            <Text color={helperTextColor} fontSize="sm">
              Una vez eliminada tus datos se perderan de forma definitiva
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              cancelar
            </Button>
            <Button colorScheme="danger" mr={3} onClick={deleteUser}>
              Eliminar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Settings;
