// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Box,
  Text,
  useColorModeValue,
  Button,
  CircularProgress,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Card from "components/Card/Card";
import Select from "react-select";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SocialCard from "components/SocialCard";

import socialApi from "services/api/social";
import { from } from "stylis";
import NewPostForm from "./components/NewPostForm";

export default function Home() {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue("secondaryLight.500", "secondaryDark.500");
  const perPage = 25;

  const [publicaciones, setPublicaciones] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loadingNewPage, handleLoadingNewPage] = useState(true);
  const [searchString, setSearchString] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    getTags();
    loadFeed();
  }, []);

  const getTags = async () => {
    await socialApi.getTags()
    .then((response) => {
      if(response.success){
        let _tags = [];
        response.tags.map((tag, i) => {
          _tags.push({value: i + 1, label: tag})
        })
        setTags(_tags);
      }
      console.log(response)
    })
  }

  const getFeed = async (page = 1) => {
    const start = (page - 1) * perPage + 1;

    let collectedData = {};

    await socialApi
      .getFeed(start)
      .then((response) => {
        if (response.success) {
          const totalPosts = response.total;
          collectedData.feed = [...response.feed];
          const evaluateLastItem = perPage > totalPosts ? totalPosts : page === metadata.last_page ? totalPosts : page * perPage
          let _metadata = {
            current_page: page,
            from: start,
            last_page: Math.floor(totalPosts / perPage) + 1,
            to: evaluateLastItem,
            total: totalPosts,
          };
          collectedData.metadata = { ..._metadata };
        }
      })
      .catch();
    return collectedData;
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const searchFeed = async (page = 1, search = "") => {
    const start = (page - 1) * perPage + 1;

    let collectedData = {};

    await socialApi
      .getFeedSearch(start, search)
      .then((response) => {
        if (response.success) {
          const totalPosts = response.total;
          collectedData.feed = [...response.items];
          const evaluateLastItem = perPage > totalPosts ? totalPosts : page === metadata.last_page ? totalPosts : page * perPage
          let _metadata = {
            current_page: page,
            from: start,
            last_page: Math.floor(totalPosts / perPage) + 1,
            to: evaluateLastItem,
            total: totalPosts,
          };
          collectedData.metadata = { ..._metadata };
        } else collectedData = { feed: [], metadata: {} };
      })
      .catch();
    return collectedData;
  };

  const searchMore = async () => {
    const old = [...publicaciones];
    handleLoadingNewPage(true);
    await searchFeed(metadata.current_page + 1, searchString)
      .then((response) => {
        setPublicaciones([...old, ...response.feed]);
        setMetadata({ ...response.metadata });
      })
      .finally(() => handleLoadingNewPage(false));
  };

  const seeMore = async () => {
    const old = [...publicaciones];
    handleLoadingNewPage(true);
    await getFeed(metadata.current_page + 1)
      .then((response) => {
        setPublicaciones([...old, ...response.feed]);
        setMetadata({ ...response.metadata });
      })
      .finally(() => handleLoadingNewPage(false));
  };

  const loadFeed = async () => {
    await getFeed()
      .then((response) => {
        setPublicaciones(response.feed);
        setMetadata({ ...response.metadata });
      })
      .finally(() => handleLoadingNewPage(false));
  };

  const onSubmit = async (values) => {
    handleLoadingNewPage(true)
    if(values.search === "" && !selectedOptions.length){
      setPublicaciones([]);
      setMetadata({});
      setSearchString("");
      loadFeed()
      return
    }
    let _searchString = "";

    selectedOptions.map((tag) => {
      _searchString = _searchString + `&tags[]=${tag.label}`;
    });

    if (values.search)
      _searchString = _searchString + `&search=${values.search}`;

    setSearchString(_searchString);

    await searchFeed(1, _searchString).then((response) => {
      setPublicaciones([...response.feed]);
      setMetadata({ ...response.metadata });
    }).finally(() => handleLoadingNewPage(false));
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "2fr 1fr" }}
        templateRows="1fr"
        gap="24px"
        p="24px"
        bgColor={primaryColor}
        borderRadius={{ sm: "10px", md: "20px" }}
      >
        <GridItem rowSpan={1}>
          <NewPostForm />
        </GridItem>

        <GridItem rowSpan={1}>
          <Card p="1rem" height="auto">
            <CardHeader>
              <Text>Buscador</Text>
            </CardHeader>
            <CardBody>
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <FormControl mb="12px" id="search">
                  <Input
                    fontSize="sm"
                    borderRadius="15px"
                    type="text"
                    placeholder="Por texto..."
                    size="sm"
                    {...register("search")}
                  />
                </FormControl>

                {/* Multiselect */}
                <FormControl mb="12px" id="search">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#6ca6e1" : "#cbd5e0",
                        borderRadius: "15px",
                        fontSize: "0.875rem",
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? "#6ca6e1"
                            : isFocused
                            ? "#6ca6e1"
                            : undefined,
                          color: isDisabled
                            ? "#ccc"
                            : isSelected
                            ? "#6ca6e1"
                            : "secondaryLight.100",
                          cursor: isDisabled ? "not-allowed" : "default",

                          ":active": {
                            ...styles[":active"],
                            backgroundColor: !isDisabled
                              ? isSelected
                                ? "#6ca6e1"
                                : "secondaryLight.100"
                              : undefined,
                          },
                          ":hover":{
                            color: "white"
                          }
                        };
                      },
                      multiValue: (styles, { data }) => {
                        return {
                          ...styles,
                          backgroundColor: "rgba(106, 227, 220, .3)",
                          borderRadius: "10px",
                        };
                      },
                      multiValueLabel: (styles, { data }) => ({
                        ...styles,
                        color: "#4D7FB5",
                      }),
                      multiValueRemove: (styles, { data }) => ({
                        ...styles,
                        color: "#4D7FB5",
                        borderRadius: "0 10px 10px 0",
                        ':hover': {
                          backgroundColor: "#4fd1c5",
                          color: 'white',
                        },
                      }),
                    }}
                    fontSize="sm"
                    type="text"
                    placeholder="Por etiquetas"
                    size="sm"
                    {...register("tags")}
                    options={tags
                    //   [
                    //   { value: 1, label: "Abstract" },
                    //   { value: 2, label: "Landscape" },
                    //   { value: 3, label: "Portrait" },
                    //   { value: 4, label: "Surreal" },
                    //   { value: 5, label: "Nature" },
                    //   { value: 6, label: "Digital Art" },
                    //   { value: 7, label: "Oil Painting" },
                    //   { value: 8, label: "Watercolor" },
                    //   { value: 9, label: "Photography" },
                    // ]
                  }
                    isMulti
                    value={selectedOptions}
                    onChange={handleSelectChange}
                  />
                </FormControl>
                <Flex w="100%" justifyContent="center">
                  <Button type="submit" colorScheme="secondaryLight">
                    Buscar
                  </Button>
                </Flex>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <Grid my="26px" gap="24px">
        {!!publicaciones.length && (
          <>
            {publicaciones.map((publicacion, i) => {
              return (
                <SocialCard
                  key={i}
                  data={publicacion}
                  isSale={publicacion.type === "artwork"}
                />
              );
            })}
          </>
        )}
      </Grid>
      <Flex w="100%" justifyContent="center">
        {loadingNewPage ? (
          <CircularProgress color={primaryColor} isIndeterminate />
        ) : (
          <Button
            variant="outline"
            colorScheme="primaryLight"
            disabled={metadata.current_page == metadata.last_page}
            onClick={!!searchString ? searchMore : seeMore}
          >
            Ver más
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
