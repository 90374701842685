import React, { useState, useEffect } from "react";
import Card from "components/Card/Card";
import { useHistory } from "react-router-dom";
import {
  Heading,
  Text,
  Flex,
  Spacer,
  Box,
  Image,
  Grid,
  GridItem,
  useColorModeValue,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CircularProgress,
  CircularProgressLabel,
  Badge,
} from "@chakra-ui/react";
import {
  MdThumbUpOffAlt,
  MdThumbUp,
  MdOutlineVisibility,
} from "react-icons/md";
import { useDisclosure } from "@chakra-ui/react";
import socialApi from "services/api/social";
import {
  HamburgerIcon,
  AddIcon,
  ExternalLinkIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { useForm, SubmitHandler, register } from "react-hook-form";
import userService from "services/storage/user";
import artworkApi from "services/api/sales";

const SocialCard = (props) => {
  const { isSale, canHighlight } = props;
  const primaryColor = useColorModeValue("primaryLight", "primaryDark");
  const titleColor = useColorModeValue("gray.600", "gray.200");
  const textColor = useColorModeValue("gray.500", "white");
  const [cardData, setCardData] = useState(null);
  const [like, handleLike] = useState();
  const [likesCount, setLikesCount] = useState(0);
  const [comments, setComments] = useState(null);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!!props.data) {
      const _data = !!props.data.item ? props.data.item : props.data;
      const parseData = JSON.parse(_data.image_url)[0];
      const auxData = {
        ..._data,
        image_url: parseData,
        type: props.data.type || "",
      };
      setCardData(auxData);
      handleLike(!!auxData.liked_by_user);
      setLikesCount(auxData.likes);
    }
  }, [props.data]);

  const fetchComments = async () => {
    console.log("Este es el fetch");

    socialApi
      .getComment(cardData.id)
      .then((response) => {
        console.log(response.status);
        //if (response.success) {
        console.log("Comentarios obtenidos con éxito:", response.comments);
        setComments(response.comments);
        console.log(comments);
        //}
      })
      .catch((error) => {
        console.error("Error al obtener comentarios:", error);
      });
  };

  const onClickToggleLike = async () => {
    await socialApi
      .likePost(cardData.id, !like, !!cardData.type ? cardData.type : props.type)
      .then((response) => {
        if (response.success) {
          setLikesCount(like ? likesCount - 1 : likesCount + 1);
          handleLike(!like);
        }
      });
  };

  const onOpenWithComments = () => {
    fetchComments();
    onOpen();
  };

  const closeWithComments = () => {
    setComments(null);
    onClose();
  };

  const { register, errors, handleSubmit, watch, setValue } = useForm();

  const onSubmit = async (data, e) => {
    try {
      // Llama a la función de la API para enviar el comentario
      const response = await socialApi.sendComment(
        cardData.id,
        data.comentario
      );

      // Verifica si la solicitud fue exitosa
      if (!!response.success) {
        // Realiza cualquier acción adicional si es necesario
        console.log("Comentario enviado con éxito");
        const _comment = { ...response.comment, user: userService.getUser() };
        setValue("comentario", "")
        setComments([...comments, _comment]);
      } else {
        console.error("Error al enviar el comentario");
      }
    } catch (error) {
      console.error("Error inesperado al enviar el comentario", error);
    }
    e.target.reset();
  };

  const artDetails = async () => {
    history.push(`/app/sales/${cardData.id}/details`);
  };

  const higlightArtwork = async () => {
    await artworkApi.highlight(cardData.id, !cardData.highlighted).then((response) => {
      console.log(response)
      if(response.success){
        history.go(0);
      }
    })
  }

  return (
    <>
      {!!cardData && (
        <Card>
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows={"repeat(2, .5fr) repeat(2, 1fr) .5fr"}
            gap={4}
            maxHeight="28rem"
          >
            {/*START::Imagen de la obra */}
            <GridItem rowStart={1} colStart={1} rowSpan={5}>
              <Box overflow="hidden" width="500" height="100%">
                <Image
                  objectFit="cover"
                  padding="2"
                  src={cardData.image_url}
                  alt="Arte"
                />
              </Box>
            </GridItem>
            {/* END::Imagen de la obra */}
            {/*START::Botón de acciones */}
            {cardData.type === "artwork" &&
            <GridItem rowStart={1} colStart={3}>
              <Box w="fit-content" ms="auto">
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<MdOutlineVisibility />}
                      onClick={artDetails}
                    >
                      Ver detalles
                    </MenuItem>
                    {canHighlight && 
                    <MenuItem
                      icon={<MdOutlineVisibility />}
                      onClick={higlightArtwork}
                    >
                      Destacar Obra
                    </MenuItem>}
                  </MenuList>
                </Menu>
              </Box>
            </GridItem>
            }
            {/* END::Botón de acciones */}
            {/*START::Titulo y precio */}
            <GridItem colSpan={2} rowStart={2} colStart={2} px="2">
              <Box>
                <Flex>
                  <Box w="fit-content">
                    <Heading color={titleColor} size="2xl">
                      {cardData.title}
                    </Heading>
                  </Box>
                  <Spacer />
                  <Flex>
                    {isSale && (
                      <Box>
                        <Heading color={titleColor} size="lg">
                          {cardData.price + "$"}
                        </Heading>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </GridItem>
            {/* END::Titulo y precio */}
            {/*START::Descripción */}
            <GridItem colSpan={2} rowStart={3} colStart={2}>
              <Box>
                <Text color={textColor}>
                  {cardData.description || cardData.content}
                </Text>
              </Box>
            </GridItem>
            {/* END::Descripción */}
            {/*START::Tags */}
            <GridItem colSpan={2} rowStart={4} colStart={2}>
              {cardData.tags && (
                <>
                  <Text ms="1rem" color="gray.500">
                    Etiquetas:
                  </Text>
                  <Flex>
                    {cardData.tags.map((tag) => {
                      return (
                        <Badge
                          colorScheme="secondaryLight"
                          color="gray.500"
                          borderRadius="15px"
                          mx="1rem"
                          py="5px"
                          px="15px"
                        >
                          {tag.name}
                        </Badge>
                      );
                    })}
                  </Flex>
                </>
              )}
            </GridItem>
            {/* END::Tags */}
            {/* START::Boton likes */}
            <GridItem rowStart={5} colStart={2}>
              <Flex
                direction={{ base: "column", md: "row" }}
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  align-items="start"
                  margin="10px"
                  // width="100%"
                  bg="transparent"
                  onClick={onClickToggleLike}
                  icon={
                    like ? (
                      <MdThumbUp size={50} color="red" />
                    ) : (
                      <MdThumbUpOffAlt size={50} color="red" />
                    )
                  }
                />
                <Text
                  fontWeight="500"
                  color={titleColor}
                  fontSize={22}
                  mt={"10px"}
                  p={0}
                >
                  {" "}
                  {`${likesCount} likes`}{" "}
                </Text>
              </Flex>
            </GridItem>
            {/* END::Boton likes */}
            {/* START::Boton comentarios */}
            {!isSale && (
              <GridItem rowStart={5} colStart={3}>
                <Button
                  justify-content="flex-end"
                  margin="10px"
                  width="100%"
                  onClick={onOpenWithComments}
                  colorScheme="red"
                  variant="outline"
                >
                  Comentar
                </Button>
              </GridItem>
            )}
            {/* END::Boton comentarios */}
          </Grid>
          <Modal isOpen={isOpen} onClose={closeWithComments}>
            <ModalOverlay />
            <ModalContent>
              {!!comments ? (
                <>
                  <ModalHeader>Comentarios</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {comments.map((comment, index) => (
                      <Flex direction={"column"}>
                        <Flex role="button" onClick={() => {
                          history.push(`/app/user/${comment.user.id}`)
                        }}>
                          <Box width="15%">
                            <Image
                              borderRadius="full"
                              boxSize="50px"
                              src={comment.user.profile_pic}
                              alt="Arte"
                            />
                          </Box>
                          <Box width="50%">
                            <Heading>{comment.user.username}</Heading>
                          </Box>
                        </Flex>
                        <Box>
                          <Heading color={titleColor} size="10px">
                            <GridItem key={index} colSpan={3}>
                              <Text>{comment.content}</Text>
                            </GridItem>
                          </Heading>
                        </Box>
                      </Flex>
                    ))}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        {...register("comentario", { required: true })}
                        placeholder="Escribe comentario..."
                      />
                      <Button type="submit" className="btn btn-primary">
                        Comentar
                      </Button>
                    </form>
                  </ModalBody>
                </>
              ) : (
                <CircularProgress isIndeterminate color="primaryLight.500" />
              )}
            </ModalContent>
          </Modal>
        </Card>
      )}
    </>
  );
};

export default SocialCard;
