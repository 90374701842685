// Chakra imports
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  CircularProgress,
  InputGroup,
  Input,
  InputLeftElement,
  FormControl,
  Stack,
  IconButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TableRow from "./TableRowTransactions";
import { TablePaginator } from "components/Tables/TablePaginator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import userServices from "services/storage/user";

import { FaSearch } from "react-icons/fa";

const DataTable = ({
  title,
  captions,
  data,
  loading,
  paginationData,
  onChangePage,
}) => {
  const textColor = useColorModeValue("white");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const history = useHistory();

  const { register, handleSubmit, getValues } = useForm();

  const { current_page, from, last_page, to, total } = paginationData;

  const canGoFurther = current_page < last_page;
  const canGoFewer = current_page > 1;

  console.log(data)

  const onDetails = (transactionId) => {
    // return <Redirect to={"/admin/user/" + userId} />
    history.push(`/${userServices.getRole() === "admin" ? "admin" : "app"}/transactions/${transactionId}/following`);
  };

  const paginationHandler = (page) => {
    const search = getValues("searchParam") ?? "";
    console.log(search);
    onChangePage(page, search);
  };

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      overflowY="scroll"
      padding="0"
    >
      <CardHeader p="6px 22px 22px 22px" bg={secondaryColor}>
        <Stack spacing={4} w="100%">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            {title}
          </Text>
        </Stack>
      </CardHeader>
      <CardBody p="0px 22px 22px 22px">
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {captions.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan="100%">
                  <Flex justifyContent="center">
                    <CircularProgress
                      isIndeterminate
                      color="primaryLight.500"
                    />
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <>
                {total >= 1 ? (
                  <>
                    {data.map((row) => {
                      row.created_at = row.created_at.split(" ")[0];
                      return (
                        <TableRow
                          key={`${row.email}-${row.username}`}
                          id={row.id}
                          clientName={row.buyer}
                          // clientName={row.buyer_id}
                          artistName={row.seller}
                          // artistName={row.seller_id}
                          artwork={row.artwork_title}
                          // artwork={row.artwork_id}
                          onDetails={onDetails}
                          status={row.status}
                          date={row.created_at?.substring(0, 10)}
                          showSeller={!!captions.find((header) => header === "Vendedor")}
                          showBuyer={!!captions.find((header) => header === "Comprador")}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Tr>
                    <Td colSpan="100%">
                      <Flex justifyContent="center">
                        <Text color="gray.400">
                          No hay registros que mostrar
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
          <TablePaginator
            paginationData={paginationData}
            onChangePage={paginationHandler}
          />
        </Table>
      </CardBody>
    </Card>
  );
};

export default DataTable;
