import React, { useState, useEffect } from "react";
import Table from "./components/Table";
import { tablesTableData } from "variables/general";
import adminServices from "services/api/admin";
import { Box, Flex, CircularProgress } from "@chakra-ui/react";

const RequestList = () => {
  const perPage = 25
  const [loader, setLoader] = useState(true);
  const [ticketList, setTicketList] = useState([]);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    getTicketList();
  }, []);

  const getTicketList = async (page = 1, search = "") => {
      const start = (page - 1) * perPage + 1;
      await adminServices
        .countTickets()
        .then(async (countResponse) => {
          if (countResponse.success) {
            const totalTickets = countResponse.total
            await adminServices.getTickets({ start, search }).then((response) => {
              if (response.success) {
                setTicketList(response.tickets);
                const evaluateLastItem = perPage > totalTickets ? totalTickets : page === metadata.last_page ? totalTickets : page * perPage
                let _metadata = {
                  current_page: page,
                  from: start,
                  last_page: Math.floor(totalTickets/perPage)+1,
                  to: evaluateLastItem,
                  total: totalTickets,
                };
                setMetadata({ ..._metadata });
              }
            });
          }
        })
        .finally(() => {
          setLoader(false);
        });
  };

    const handleChangePage = async (page, searchParam = "") => {
      setLoader(true);
      setUserList([]);
      setMetadata({});
      await getTicketList(page, searchParam);
    };

  const handleSearchByUser = async (searchParam) => {
    console.log(searchParam);
    setLoader(true);
    setTicketList([]);
    setMetadata({});
    await getTicketList(1, searchParam);
  };

  const handleReject = async (requestId) => {
    const formData = new FormData();
    formData.append("status", "rejected");
    formData.append("reason_for_rejection", "Rechazo de control");
    formData.append("_method", "PUT");

    await adminServices
      .rejectTicket(formData, requestId)
      .then((response) => console.log(response));
  };

  const handleAccept = async (requestId) => {
    const formData = new FormData();
    formData.append("status", "accepted");
    formData.append("_method", "PUT");

    await adminServices
      .rejectTicket(formData, requestId)
      .then((response) => console.log(response));
  };

  return (
    <Flex
      flexDirection="column"
      marginTop={{ base: "120px", md: "75px" }}
      direction="column"
      justifyContent="center"
      id="container"
    >
      <Flex height={"70vh"} p="0px">
        <Table
          title={"Lista de solicitudes"}
          captions={[
            "User",
            "Request date",
            "Response date",
            "Rejection reason",
            "Status",
            "Actions",
          ]}
          data={ticketList}
          loading={loader}
          onAccept={handleAccept}
          onReject={handleReject}
          paginationData={metadata}
          onChangePage={handleChangePage}
          onSearch={handleSearchByUser}
        />
      </Flex>
    </Flex>
  );
};

export default RequestList;
