import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  useColorModeValue,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  Textarea,
  Avatar
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import salesApi from "services/api/sales"; // Importa salesApi
import { FaEllipsisV } from "react-icons/fa";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Carousel } from "react-responsive-carousel";
import QnASection from "./components/QnASection";
import userServices from "services/storage/user";
import userApi from "services/api/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ArtworkDetail = (props) => {
  const [artwork, setArtwork] = useState(null);
  const [loading, setLoading] = useState(true);
  const textColor = useColorModeValue("white");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );

  const { register, errors, handleSubmit, watch } = useForm();

  const history = useHistory();

  const artworkId = props.match.params.id;
  const user = userServices.getUser();
  const [available, setAvailable] = useState(false);
  const [isArtCreator, setIsArtCreator] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answerModal, toggleAnswerModal] = useState(false);
  const [answerModalData, setAnswerModalData] = useState({});
  const [artist, setArtist] = useState();

  useEffect(() => {
    fetchArtwork();
  }, []);

  const fetchArtwork = async () => {
    try {
      const response = await salesApi.detail(artworkId);
      if (response.success) {
        setArtwork(response.data);
        setLoading(false);
        setAvailable(response.data.status === "Listado");
        setIsArtCreator(response.data.user_id === user.id);
        getArtistData(response.data.user_id)
        if (response.data.qna_enabled) getQnA();
      }
    } catch (error) {
      console.error("Error fetching artwork detail:", error);
    }
  };

  const getArtistData = async (id) => {
    await userApi.getUser(id).then((response) => setArtist(response.user))
  } 

  const getQnA = async () => {
    await salesApi.countQnA(artworkId).then(async (response) => {
      const total = response.count;
      if (!!total) {
        await salesApi.getQnA(artworkId, total).then((qnaResponse) => {
          if (qnaResponse.success) setQuestions(qnaResponse.questions);
        });
      }
    });
  };

  const buyArtwork = async () => {
    await salesApi.buyArtwork(artworkId).then((response) => {
      if (response.success) {
        console.log("Compra realizada con éxito");
        setAvailable(false);
        setArtwork({ ...artwork, status: "Apartado" });
      }
    });
  };

  const handleAnswerModal = (question) => {
    console.log(question);
    toggleAnswerModal(!answerModal);
    setAnswerModalData(question);
  };

  const onSubmit = async (values) => {
    console.log("trying")
    const formData = new FormData();
    if (isArtCreator) {
      formData.append("answer", values.answer);
      formData.append("_method", "PUT");
      await salesApi
        .answerQuestion(answerModalData.id, formData)
        .then((response) => {
          if (response.success) {
            getQnA();
            toggleAnswerModal(false);
          }
        });
    } else {
      formData.append("question", values.question);
      await salesApi.createQuestion(artwork.id, formData).then((response) => {
        if (response.success) {
          getQnA();
          toggleAnswerModal(false);
        }
      });
    }
  };

  console.log(isArtCreator)

  return (
    <Flex
      flexDirection="column"
      marginTop={{ base: "120px", md: "75px" }}
      direction="column"
      justifyContent="center"
      id="container"
    >
      <Flex height={"70vh"} p="0px">
        <Card
          overflowX={{ sm: "scroll", xl: "hidden" }}
          overflowY="scroll"
          padding="0"
        >
          <CardHeader p="6px 22px 22px 22px" bg={secondaryColor}>
            <Stack spacing={4} w="100%">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Detalles de la obra
              </Text>
            </Stack>
            {!isArtCreator && (
              <Button disabled={!available} onClick={buyArtwork}>
                Comprar ahora
              </Button>
            )}
          </CardHeader>
          <CardBody p="0px 22px 22px 22px">
            {loading ? (
              <div>Cargando...</div>
            ) : artwork ? (
              <Table title={"Artwork Detail"}>
                <Tbody>
                  <Tr h="fitContent">
                    <Td>Creador</Td>
                    <Td>
                      <Flex
                        my="1.3rem"
                        role="button"
                        onClick={() => {
                          history.push(`/app/user/${artist.id}`);
                        }}
                      >
                        <Avatar
                          src={artist?.profile_pic}
                          h="40px"
                          w="40px"
                          me="12px"
                        />
                        <Text my="auto" fontSize="lg">
                          {artist?.username}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Título</Td>
                    <Td>{artwork.title}</Td>
                  </Tr>
                  <Tr h="fit-content">
                    <Td>Descripción</Td>
                    <Td>{artwork.description}</Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Precio</Td>
                    <Td>{artwork.price}</Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Estatus</Td>
                    <Td>{artwork.status}</Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Fecha de publicación</Td>
                    <Td>{artwork.created_at}</Td>
                  </Tr>
                  <Tr>
                    <Td>Imagenes de la obra</Td>
                    <Td>
                      <Carousel>
                        <div>
                          {JSON.parse(artwork.image_url).map((url, index) => (
                            <div key={index}>
                              <img
                                src={url}
                                alt={`Artwork ${index}`}
                                style={{ maxWidth: "200px" }}
                              />
                            </div>
                          ))}
                        </div>
                      </Carousel>
                    </Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Etiquetas</Td>
                    <Td>{artwork.artwork_tags.join(", ")}</Td>
                  </Tr>
                  <Tr h="1rem">
                    <Td>Likes</Td>
                    <Td>{artwork.likes}</Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <div>No se encontró la obra.</div>
            )}
            {artwork?.qna_enabled && (
              <QnASection
                questions={questions}
                isArtCreator={isArtCreator}
                onAnswer={handleAnswerModal}
              />
            )}
          </CardBody>
        </Card>
      </Flex>
      <Modal isOpen={answerModal}>
        <ModalOverlay>
          <ModalContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>
                <Text>{answerModalData.question}</Text>
              </ModalHeader>
              <ModalBody>
                {isArtCreator ? (
                  <Textarea {...register("answer")} />
                ) : (
                  <Textarea {...register("question")} />
                )}
              </ModalBody>
              <ModalFooter justifyContent="space-between">
                <Button
                  colorScheme="danger"
                  onClick={() => {
                    toggleAnswerModal(false);
                    setAnswerModalData({});
                  }}
                >
                  Cancelar
                </Button>
                <Button type="submit" colorScheme="secondaryLight">
                  Enviar
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Flex>
  );
};

export default ArtworkDetail;
