import React, { useState, useEffect } from "react";
import {
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

import userApi from "services/api/user";
import ProfileHeader from "./components/ProfileHeader";
import PostList from "./components/PostList";

const Profile = (props) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const headerTextColor = useColorModeValue("gray.600", "gray.600")
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");

  const selfProfile = (props.match.path === "/app/my-profile" || props.match.path === "/admin/my-profile");

  const [userData, setUserData] = useState({});

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    if(selfProfile) {
      await userApi.getUser().then((response) => {
        if(response.success) setUserData(response.user)
      });
    }
    else {
      await userApi.getUser(props.match.params.id).then((response) => {
        if(response.success) setUserData(response.user)
      });
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {!!userData && (
        <ProfileHeader data={userData} selfProfile={selfProfile}/>
        )}
        <PostList selfProfile={selfProfile} user={userData} {...props} ></PostList>
    </Flex>
  );
};

export default Profile;
