import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Avatar,
  Box,
  useColorModeValue,
  Grid,
  GridItem,
  Badge,
  Textarea,
  FormControl,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import ConversationBox from "./components/ConversationBox";

import userApi from "services/api/user";
import messagesApi from "services/api/messages";
import userServices from "services/storage/user";

const Conversation = (props) => {
  const primaryColor = useColorModeValue("primaryLight", "primaryDark");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.400"
  );
  const titleColor = useColorModeValue("gray.600", "gray.200");
  const textColor = useColorModeValue("gray.500", "white");

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  const [user, setUser] = useState({});
  const [conversation, setConversation] = useState([]);

  useEffect(() => {
    getUser();
    getMessages();

    const interval = setInterval(() => {
      getMessages();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  console.log(props.match);

  const getUser = async () => {
    await userApi.getUser(location.state.receiverId).then((response) => {
      if (response.success) {
        setUser(response.user);
      }
    });
  };

  const getMessages = async () => {
    await messagesApi
      .getConversation(props.match.params.conversation_id)
      .then((response) => {
        if (response.success) {
          const data = response.messages.map((message) => {
            return {
              content: message.content,
              sender: message.sender_id,
              receiver: message.receiver_id,
              date: message.created_at,
            };
          });

          setConversation(data);
        }
      });
  };

  const onSubmit = async (values) => {
    if (!values.message) return;
    const formData = new FormData();

    formData.append("content", values.message);

    await messagesApi
      .sendMessage(formData, props.match.params.conversation_id)
      .then((response) => {
        if (response.success) {
          let _newMessage = {
            date: "now",
            sender: userServices.getId(),
            receiver: user.id,
            content: values.message
          };
          let _messages = [...conversation]
          _messages.push(_newMessage)
          setConversation(_messages)
        }
      });
  };

  console.log(conversation);

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="relative"
        w={{ base: "95%", md: "75%" }}
        // h={{ base: "80vh", md: "45rem" }}
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="25px"
        display="inline"
      >
        <Flex
          w="100%"
          h="6rem"
          backgroundColor={secondaryColor}
          borderTopRadius="inherit"
          justifyContent="start"
          alignItems="center"
        >
          <Avatar ms="2rem" me="1rem" size="lg" src={user.profile_pic} />
          <Flex direction="column">
            <Text fontWeight="700" my=".1rem">
              {user.username}
            </Text>
            <Text my=".1rem">{user.nickname}</Text>
          </Flex>
        </Flex>
        <ConversationBox messages={conversation} />
        <Box
          mt="auto"
          position="relative"
          bottom={0}
          w="100%"
          h="fit-content"
          backgroundColor={secondaryColor}
          borderBottomRadius="inherit"
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Flex
              wrap="nowrap"
              direction={{ base: "column", md: "row" }}
              justify={{ base: "center", md: "space-arround" }}
              px="1rem"
              py={{ base: "1rem", md: 0 }}
            >
              <FormControl
                m={{ base: 0, md: "1rem" }}
                id="description"
                w={{ base: "100%", md: "75%" }}
              >
                <Textarea
                  fontSize="sm"
                  borderRadius="15px"
                  rows={6}
                  placeholder="Escribe un mensaje"
                  // size="lg"
                  bg="white"
                  // required
                  {...register("message")}
                />
              </FormControl>
              <Button
                justifySelf="center"
                alignSelf="center"
                fontSize="16px"
                type="submit"
                colorScheme="primaryLight"
                // h="45"
                // mb="20px"
                color="white"
                mt="1rem"
              >
                ENVIAR
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default Conversation;
