import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  CheckboxGroup,
  useDisclosure,
  Divider,
  CircularProgress,
} from "@chakra-ui/react";
import { MdCancel } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2"

import EvidenceGrid from "../../Admin/ArtistRequests/components/EvidenceGrid";

import userServices from "services/storage/user";
import adminApi from "services/api/admin";
import salesApi from "services/api/sales";

const schema = yup.object({
  fileString: yup.string().required(),
});

const FollowSaleAdmin = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const [user, setUser] = useState({});
  const [transaction, setTransaction] = useState({});
  const [loader, toggleLoader] = useState(true);

  useEffect(() => {
    getTransaction();
  }, []);

  useEffect(() => {
    setValue("artwork_received", Boolean(transaction.artwork_received));
    setValue("payment_received", Boolean(transaction.payment_received));
  }, [transaction]);

  console.log(getValues("payment_received"));

  const getTransaction = async () => {
    await salesApi
      .getTransaction(props.match.params.id)
      .then((response) => {
        if (response.success) {
          setTransaction({
            ...response.transaction,
            payment_confirmation: JSON.parse(
              response.transaction.payment_confirmation
            ),
            artwork_confirmation: JSON.parse(
              response.transaction.artwork_confirmation
            ),
          });
        }
      })
      .catch()
      .finally(() => toggleLoader(!loader));
  };

  const onSubmit = async (values) => {
    console.log(values);
    const formData = new FormData();
    formData.append("artwork_received", values.artwork_received ? 1 : 0);
    formData.append("payment_received", values.payment_received ? 1 : 0);
    await adminApi
      .updateTransaction(formData, props.match.params.id)
      .then((response) => {
        if(response.success){
          Swal.fire({
            title: "Transacción actualizada",
            icon: "success",
            text:
            `La transacción ha sido actualizada exitosamente.`,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: "#4fd1c5",
          });
          history.push('/admin/transactions');
        } else {
          Swal.fire({
            title: "Algo salió mal",
            icon: "warning",
            text:
              "Ocurrio un problema al actualizar la transacción. Recargue la página y vuelva a intentarlo.",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            cancelButtonColor: "#4fd1c5",
          });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDirection={"column"}
        pt={{ base: "120px", md: "75px" }}
        wrap="wrap"
        justifyContent="center"
        alignItems="center"
        minWidth={"26rem"}
      >
        {loader ? (
          <Flex justifyContent="center">
            <CircularProgress isIndeterminate color="primaryLight.500" />
          </Flex>
        ) : (
          <Flex
            flexDirection={"column"}
            wrap="wrap"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Text>Comprobante de compra</Text>
            {!!transaction && transaction.payment_confirmation ? (
              <EvidenceGrid evidence={transaction.payment_confirmation || []} />
            ) : (
              <Box>
                <Text>Pendiente de pago</Text>
              </Box>
            )}
            <Divider borderColor={primaryColor} borderWidth="1px" my="2rem" />
            <Text>Comprobante de envio</Text>
            {!!transaction && transaction.artwork_confirmation ? (
              <EvidenceGrid evidence={transaction.artwork_confirmation || []} />
            ) : (
              <Box>
                <Text>Pendiente de pago</Text>
              </Box>
            )}
            <Divider borderColor={primaryColor} borderWidth="1px" my="2rem" />
            <>
              <Flex>
                <FormControl mb="1.3rem">
                  {/* <CheckboxGroup > */}
                  <Checkbox
                    p="10px"
                    {...register("payment_received")}
                    disabled={!transaction.payment_confirmation}
                  >
                    Pago recibido
                  </Checkbox>
                  <Checkbox
                    p="10px"
                    {...register("artwork_received")}
                    disabled={!transaction.artwork_confirmation}
                  >
                    Obra recibida
                  </Checkbox>
                  {/* </CheckboxGroup> */}
                </FormControl>
              </Flex>
              <Box>
                <Button
                  type="submit"
                  colorScheme={"primaryLight"}
                  fontSize="10px"
                  color="white"
                  fontWeight="bold"
                  ms="4px"
                  w="10rem"
                  h="45"
                  mb="24px"
                  disabled={
                    !transaction.artwork_confirmation &&
                    !transaction.payment_confirmation
                  }
                >
                  ENVIAR
                </Button>
              </Box>
            </>
          </Flex>
        )}
      </Flex>
    </form>
  );
};

export default FollowSaleAdmin;
