// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  modal,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SocialCard from "components/SocialCard";
import socialApi from "services/api/social";

//Borrar despues
//import { publicacion as publicacionTest } from "data/store";

const Social = () => {
  const perPage = 25;
  const iconBoxInside = useColorModeValue("white", "white");
  const primaryColor = useColorModeValue("primaryLight", "primaryDark");

  const [publicaciones, setPublicaciones] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getFeed();
  }, []);

  const getFeed = async (page = 1) => {
    // const start = (page - 1) * perPage + 1;
    // await socialApi
    //   .getSocial(start)
    //   .then((response) => {
    //     if (response.success) {
    //       setPublicaciones(response.posts);
    //       let _metadata = {
    //         current_page: page,
    //         from: start,
    //         last_page: Math.floor(total / perPage) + 1,
    //         to: page === metadata.last_page ? total : page * perPage,
    //         total: total,
    //       };
    //       setMetadata({ ..._metadata });
    //     }
    //   })
    //   .catch();
      const start = (page - 1) * perPage + 1;
      await socialApi
        .countSocial()
        .then(async (countResponse) => {
          if (countResponse.success) {
            const totalUsers = countResponse.count
            await socialApi.getSocial(start).then((response) => {
              if (response.success) {
                setPublicaciones(response.posts);
                let _metadata = {
                  current_page: page,
                  from: start,
                  last_page: Math.floor(totalUsers/perPage)+1,
                  to: page === metadata.last_page ? totalUsers : page * perPage,
                  total: totalUsers,
                };
                setMetadata({ ..._metadata });
              }
            });
          }
        })
        // .finally(() => {
        //   setLoader(false);
        // });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ md: "1fr", lg: "2fr 1fr" }}
        templateRows="1fr"
        gap="24px"
        p="24px"
        bgColor={primaryColor}
        borderRadius={{ sm: "10px", md: "20px" }}
      >
        <GridItem rowSpan={1}>
          <Card p="1rem" height="150px">
            <CardHeader>
              <Text>Ñ</Text>
            </CardHeader>
            <CardBody>
              <Text>Placeholder de los elementos de la Publicacion</Text>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem rowSpan={1}>
          <Card p="1rem" height="auto">
            <CardHeader>
              <Text>Busqueda</Text>
            </CardHeader>
            <CardBody>
              <Text>Placeholder de los elementos de la Busqueda</Text>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
      <Grid my="26px" gap="24px">
        {!!publicaciones.length && (
          <>
            {publicaciones.map((publicacion, i) => {
              return <SocialCard key={i} data={publicacion} isSale={false} />;
            })}
          </>
        )}
      </Grid>
    </Flex>
  );
};
export default Social;
