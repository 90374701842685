// import
import Dashboard from "views/Dashboard/Dashboard";
import Home from "views/Inkdex/Home";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Social from "views/Inkdex/Social";
import Profile from "views/Inkdex/Profile";
import Settings from "views/Inkdex/Settings";
import Sales from "views/Inkdex/Sales/CreateSale";
import Notifications from "views/Inkdex/Notifications";
import ListSales from "views/Inkdex/Sales/ListSale";
import TransactionList from "views/Inkdex/Sales/TransactionList";
import ProductDetail from "views/Inkdex/Sales/ProductDetail";
import UpdateUser from "views/Inkdex/Settings/UpdateUser";
import ChangePass from "views/Inkdex/Settings/ChangePass";
import ArtistRequest from "views/Inkdex/Settings/ArtistRequest";
import UserList from "views/Admin/Users";
import RequestList from "views/Admin/ArtistRequests";

import {
  HomeIcon,
  PersonIcon,
  GlobeIcon,
  SettingsIcon,
  RocketIcon,
  BellIcon,
  WalletIcon
} from "components/Icons/Icons";

import { FaUserGroup, FaPalette, FaBell } from "react-icons/fa6";

var dashRoutes = [
  {
    path: "/home",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Home,
    layout: "/app",
  },
  {
    path: "/my-profile",
    name: "Perfil",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    component: Profile,
    layout: "/app",
  },
  {
    path: "/settings",
    name: "Configuraciones",
    rtlName: "لوحة القيادة",
    icon: <SettingsIcon color="inherit" />,
    component: Settings,
    layout: "/app",
  },
  {
    path: "/transactions",
    name: "Transacciones",
    rtlName: "لوحة القيادة",
    icon: <WalletIcon color="inherit" />,
    component: TransactionList,
    layout: "/app",
  },
  {
    path: "/notifications",
    name: "Notificaciones",
    rtlName: "لوحة القيادة",
    icon: <FaBell color="inherit" />,
    component: Notifications,
    layout: "/app",
  },
  
];
var artistRoutes = [
  {
    path: "/sales/list",
    name: "Mis obras",
    rtlName: "لوحة القيادة",
    icon: <FaPalette color="inherit" />,
    component: ListSales,
    layout: "/app",
  },
]

var adminRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: <HomeIcon color="inherit" />,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/my-profile",
    name: "Perfil",
    icon: <PersonIcon color="inherit" />,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Configuraciones",
    icon: <SettingsIcon color="inherit" />,
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: <FaUserGroup color="inherit" />,
    component: UserList,
    layout: "/admin",
  },
  {
    path: "/artist-tickets",
    name: "Solicitudes de artista",
    icon: <FaPalette color="inherit" />,
    component: RequestList,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transacciones",
    rtlName: "لوحة القيادة",
    icon: <WalletIcon color="inherit" />,
    component: TransactionList,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notificaciones",
    rtlName: "لوحة القيادة",
    icon: <FaBell color="inherit" />,
    component: Notifications,
    layout: "/admin",
  },
  
];

const settingsRoutes = [
  {
    path: "/settings/edit-profile",
    name: "Edit Profile",
    component: UpdateUser,
    layout: "/app",
  },
  {
    path: "/settings/change-password",
    name: "Change Password",
    component: ChangePass,
    layout: "/app",
  },
  {
    path: "/settings/inkdex-me",
    name: "Inkdex Me",
    component: ArtistRequest,
    layout: "/app",
  },
];

const adminSettingsRoutes = [
  {
    path: "/settings/edit-profile",
    name: "Edit Profile",
    component: UpdateUser,
    layout: "/admin",
  },
  {
    path: "/settings/change-password",
    name: "Change Password",
    component: ChangePass,
    layout: "/admin",
  },
  {
    path: "/settings/inkdex-me",
    name: "Inkdex Me",
    component: ArtistRequest,
    layout: "/admin",
  },
];
export default { dashRoutes, adminRoutes, settingsRoutes, adminSettingsRoutes, artistRoutes };
