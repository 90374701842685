import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
} from "@chakra-ui/react";
import React from "react";

import { FaEllipsisV } from "react-icons/fa";

function TableRowRequests(props) {
  const {
    id,
    user_id,
    name,
    status,
    requestDate,
    responseDate,
    rejectionReason,
    actions = [],
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const colorStatus = useColorModeValue("white", "gray.400");

  const evaluateStatus = (evaluationOutputs) => {
    if(status === "pending") return evaluationOutputs[0]
    else if (status === "accepted") return evaluationOutputs[1]
    else if (status === "rejected") return evaluationOutputs[2]
    else return evaluationOutputs[3]
  }

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {user_id}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {requestDate}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {responseDate || "N/A"}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" >
          {rejectionReason || "-"}
        </Text>
      </Td>
      <Td>
        <Badge
        //   bg={status ? "green.400" : bgStatus}
          bg={evaluateStatus([ warningStatus, successStatus, dangerStatus, noStatus])}
        //   color={status ? "white" : colorStatus}
          color={status ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {evaluateStatus(["Pendiente", "Aceptado", "Rechazado", "Desconocido"])}
        </Badge>
      </Td>
      <Td>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisV />}
            color={textColor}
            variant="outline"
          />
          <MenuList>
            {actions.map((action, key) => {
              if (!status && action.id === "delete") return <></>;
              return (
                <MenuItem
                  key={key}
                  onClick={() => action.action(action.id === "user" ? user_id : id)}
                  textColor={textColor}
                >
                  {action.title}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TableRowRequests;
