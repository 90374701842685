// Chakra imports
import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    CircularProgress,
    InputGroup,
    Input,
    InputLeftElement,
    FormControl,
    Stack,
    IconButton,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import TableRowRequests from "./TableRowRequests";
  import { TablePaginator } from "components/Tables/TablePaginator";
  import React, { useEffect, useState } from "react";
  import { useForm } from "react-hook-form";
  import { useHistory } from "react-router-dom";
  
  import { FaSearch } from "react-icons/fa";
  
  const DataTable = ({
    title,
    captions,
    data,
    loading,
    paginationData,
    onChangePage,
    onSearch,
  }) => {
    const textColor = useColorModeValue("white");
    const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
    const secondaryColor = useColorModeValue(
      "secondaryLight.500",
      "secondaryDark.500"
    );
  
    const history = useHistory();
  
    const { register, handleSubmit, getValues } = useForm();
  
    const onUserDetails = (userId) => {
      // return <Redirect to={"/admin/user/" + userId} />
      history.push("/admin/user/" + userId)
    };
  
    const onRequestDetails = (userId) => {
      // return <Redirect to={"/admin/user/" + userId} />
      history.push("/admin/artist-request/" + userId)
    };
  
    const actions = [
      { id: "user", title: "Ver perfil", action: onUserDetails },
      { id: "details", title: "Ver solicitud", action: onRequestDetails },
      // { id: "reject", title: "Rechazar", action: onReject },
    ];
  
    const onSubmit = (values) => {
      onSearch(values.searchParam);
    };
  
    const paginationHandler = (page) => {
      const search = getValues("searchParam") ?? "";
      console.log(search)
      onChangePage(page, search);
    };
  
    return (
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        overflowY="scroll"
        padding="0"
      >
        <CardHeader p="6px 22px 22px 22px" bg={secondaryColor}>
          <Stack spacing={4} w="100%">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {title}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl w={{ base: "100%", md: "30%" }}>
                <InputGroup>
                  <InputLeftElement mt=".2rem" role="button">
                    <IconButton
                      type="submit"
                      colorScheme="primaryLight"
                      backgroundColor="transparent"
                      _hover={{ backgroundColor: "transparent" }}
                    >
                      <FaSearch size="1.5rem" />
                    </IconButton>
                  </InputLeftElement>
                  <Input
                    fontSize="md"
                    borderRadius="15px"
                    type="text"
                    placeholder="Buscar"
                    size="lg"
                    textColor={textColor}
                    _placeholder={{ color: "gray.300" }}
                    {...register("searchParam")}
                  />
                </InputGroup>
              </FormControl>
            </form>
          </Stack>
        </CardHeader>
        <CardBody p="0px 22px 22px 22px">
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => {
                  return (
                    <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan="100%">
                    <Flex justifyContent="center">
                      <CircularProgress
                        isIndeterminate
                        color="primaryLight.500"
                      />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                <>
                  {data.length >= 1 ? (
                    <>
                      {data.map((row) => {
                        row.created_at = row.created_at.split(" ")[0];
                        return (
                          <TableRowRequests
                            key={`${row.id}-${row.user_id}`}
                            id={row.id}
                            user_id={row.user_id}
                            name={row.username}
                            status={row.status}
                            requestDate={row.created_at.substring(0, 10)}
                            responseDate={row.response_date?.substring(0, 10) || null}
                            rejectionReason={row.reason_for_rejection}
                            actions={actions}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <Tr>
                      <Td colSpan="100%">
                        <Flex justifyContent="center">
                          <Text color="gray.400">
                            No hay registros que mostrar
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </Tbody>
            <TablePaginator paginationData={paginationData} onChangePage={paginationHandler} />
          </Table>
        </CardBody>
      </Card>
    );
  };
  
  export default DataTable;
  