// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  Icon,
  Input,
  Link,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
//Library resources
import React, { useEffect, useState } from "react";
import { Link as ReachLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
//API calls
import authApi from "services/api/auth" 
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

const schema = yup.object({
  username: yup.string().required(),
  nickname: yup.string().required(),
  email: yup.string().email("Debe ser un email valido").required(),
  password: yup
    .string()
    .min(8, "La contraseña debe contener al menos 8 caracteres")
    .required(),
});

function SignUp() {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  const history = useHistory();

  const bgColor = useColorModeValue("white", "gray.700");
  const titleColor = useColorModeValue("primaryLight.500", "primaryDark");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark");
  const secondaryColor = useColorModeValue("secondaryLight.500", "secondaryDark.500");
  const textColor = useColorModeValue("gray.400", "white");

  console.log(errors);

  const onSubmit = async (values) => {
    await authApi.signup({...values, password_confirmation: values.password}).then(response => {
      if(response) {
        history.push("/");
      }
    }).catch(e => console.log("Error:", e))
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 24rem)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgColor={secondaryColor}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="4.5rem"
        mb="10px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
          ¡Bienvenido!
        </Text>
        <Text
          fontSize="md"
          color="white"
          fontWeight="normal"
          mt="10px"
          mb="26px"
          w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}
        >
          Explora un universo de creatividad en Inkdex. Únete a nosotros y da
          vida al arte.
        </Text>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          alignItems="center"
          justifyContent="center"
          mb={{ base: "40px", md: "20px" }}
          mt="10px"
        >
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Text
              fontSize="xl"
              color={primaryColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
              ms="4px"
            >
              Registrate
            </Text>
            <FormControl mb="24px" ms="4px" id="username">
              <FormLabel fontSize="sm" fontWeight="normal">
                Username
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="text"
                placeholder="Username"
                size="lg"
                required
                {...register("username")}
              />
              <FormHelperText>
                Un nombre unico para identificarte.
              </FormHelperText>
            </FormControl>
            <FormControl mb="24px" ms="4px" id="nickname">
              <FormLabel fontSize="sm" fontWeight="normal">
                Nombre
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="text"
                placeholder="Nombre"
                size="lg"
                {...register("nickname")}
              />
              <FormHelperText>
                {
                  "Tu nombre o apodo artístico (este es el que aparecerá en tu perfil)."
                }
              </FormHelperText>
            </FormControl>
            <FormControl mb="24px" ms="4px" id="email">
              <FormLabel fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="email"
                placeholder="Correo electrónico"
                size="lg"
                {...register("email")}
              />
            </FormControl>
            <FormControl mb="24px" ms="4px" id="password">
              <FormLabel fontSize="sm" fontWeight="normal">
                Contraseña
              </FormLabel>
              <Input
                fontSize="sm"
                borderRadius="15px"
                type="password"
                placeholder="Contraseña"
                size="lg"
                {...register("password")}
              />
            </FormControl>
            <Button
              type="submit"
              bg={primaryColor}
              fontSize="10px"
              color="white"
              fontWeight="bold"
              ms="4px"
              w="100%"
              h="45"
              mb="24px"
            >
              ENVIAR
            </Button>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
              ms="4px"
            >
              <Text color={textColor} fontWeight="medium">
                ¿Ya tienes una cuenta?
                <Link
                  color={titleColor}
                  as={ReachLink}
                  to="/auth/login"
                  ms="5px"
                  fontWeight="bold"
                >
                  Inicia sesión
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}

export default SignUp;
