// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

import Profile from "views/Inkdex/Profile";
import Messages from "views/Inkdex/Messages";
import Conversation from "views/Inkdex/Messages/Conversation";

import userServices from "services/storage/user";
import Chatbar from "components/Navbars/Chatbar";
import Sales from "views/Inkdex/Sales/CreateSale";
import Details from "views/Inkdex/Sales/ProductDetail";
import FollowSale from "views/Inkdex/Sales/FollowSale";

import user from "services/storage/user";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("opaque");
  const [fixed, setFixed] = useState(false);
  const [userRole, setUserRole] = useState()

  useEffect(() => {
    setUserRole(user.getRole())

    const interval = setInterval(() => {
      setUserRole(user.getRole())
    }, 10000);
    
    return () => clearInterval(interval);
    
  }, [])
  console.log(userRole)
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/app/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    if (!userServices.isAuth()) return <Redirect to="/auth" />;
    if (userServices.isAdmin()) return <Redirect to="/admin" />;
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode
  return (
      <ChakraProvider theme={theme} resetCss={false}>
        <Sidebar
          routes={user.getRole() == "artist" ? [...routes.dashRoutes, ...routes.artistRoutes] : [...routes.dashRoutes]}
          logoText={"INKDEX"}
          display="none"
          sidebarVariant={sidebarVariant}
          {...rest}
        />
        <MainPanel
          w={{
            base: "100%",
            xl: "calc(100% - 275px)",
          }}
        >
          <Portal>
            <AdminNavbar
              onOpen={onOpen}
              logoText={"INKDEX"}
              brandText={getActiveRoute(routes.dashRoutes)}
              secondary={getActiveNavbar(routes.dashRoutes)}
              fixed={fixed}
              {...rest}
            />
          </Portal>
          {getRoute() ? (
            <PanelContent pe={{ base: "inherit", xl: "275px" }}>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes.dashRoutes)}
                  {userRole == "artist" && getRoutes(routes.artistRoutes)}
                  {getRoutes(routes.settingsRoutes)}
								  <Route exact path="/app/sales/create" component={Sales} />
                  <Route exact path="/app/sales/:id/details" component={Details} />
								  <Route exact path="/app/user/:id" component={Profile} />
								  <Route exact path="/app/transactions/:id/following" component={FollowSale} />
								  <Route exact path="/app/messages" component={Messages} />
								  <Route exact path="/app/message/user/:conversation_id" component={Conversation} />
                  <Redirect from="/app" to="/app/home" />
                </Switch>
              </PanelContainer>
            </PanelContent>
          ) : null}
        </MainPanel>
        <Chatbar routes={[]} sidebarVariant={sidebarVariant} />
      </ChakraProvider>
  );
}
