import React, { useEffect, useState } from "react";
import { Link as ReachLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
//API calls
import authApi from "services/api/auth";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";

const schema = yup.object({
  login: yup.string().required(),
  password: yup
    .string()
    .min(8, "La contraseña debe contener al menos 8 caracteres")
    .required(),
});

function SignIn() {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  const history = useHistory();

  // Chakra color mode
  const titleColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue("secondaryLight.500", "secondaryDark.500");
  const textColor = useColorModeValue("gray.400", "white");

  const onSubmit = async (values) => {
    await authApi.login(values).then(response => {
      if(response) {
        history.push("/");
      }
    }).catch(e => console.log("Error:", e))
  };
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "150px", lg: "80px" }}
            >
              <Heading color={titleColor} fontSize="32px" mb="10px">
                ¡Bienvenido!
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                Ingresa tu nombre de username y contraseña para entrar.
              </Text>
              <FormControl mb="24px" ms="4px" id="username">
                <FormLabel fontSize="sm" fontWeight="normal">
                  Username
                </FormLabel>
                <Input
                  fontSize="sm"
                  borderRadius="15px"
                  type="text"
                  placeholder="Username"
                  size="lg"
                  required
                  {...register("login")}
                />
              </FormControl>
              <FormControl mb="24px" ms="4px" id="password">
                <FormLabel fontSize="sm" fontWeight="normal">
                  Contraseña
                </FormLabel>
                <Input
                  fontSize="sm"
                  borderRadius="15px"
                  type="password"
                  placeholder="Contraseña"
                  size="lg"
                  {...register("password")}
                />
              </FormControl>
              <Button
                fontSize="10px"
                type="submit"
                bg={primaryColor}
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
              >
                INICIAR SESIÓN
              </Button>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  ¿Aun no tienes una cuenta?
                  <Link
                    color={titleColor}
                    ms="5px"
                    fontWeight="bold"
                    as={ReachLink}
                    to="/auth/signup"
                  >
                    Registrate
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </form>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bg={secondaryColor}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
