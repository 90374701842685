import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Text,
  Grid,
  GridItem,
  Box,
  Image,
  Input,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Button,
  Textarea,
  Link,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import socialApi from "services/api/social";

import userServices from "services/storage/user";
// import { Link } from "react-router-dom/cjs/react-router-dom";

const schema = yup.object({
  fileString: yup.string().required(),
  content: yup.string().required(),
});

const NewPostForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = userServices;

  const dangerStatus = useColorModeValue("danger.500", "#1a202c");
  const warningStatus = useColorModeValue("warning.500", "warning.500");
  const successStatus = useColorModeValue("green.400", "green.400");
  const noStatus = useColorModeValue("gray.500", "gray.500");
  const primaryColor = useColorModeValue("primaryLight.500", "primaryDark.500");
  const secondaryColor = useColorModeValue(
    "secondaryLight.500",
    "secondaryDark.500"
  );
  const dangerColor = useColorModeValue("danger.500", "#D32F2F");

  const history = useHistory();
  //   const [user, setUser] = useState({});
  const [images, setImages] = useState([]);
  const [urlImages, setUrlImages] = useState([]);

  const handleImageChange = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    console.log("date");
    const imageArray = [];
    const newFiles = [];
    if (file.type.startsWith("image/") && file.size <= 2 * 1024 * 1024) {
      const url = URL.createObjectURL(file);
      imageArray.push(url);
      newFiles.push(file);
    }
    setValue("fileString", JSON.stringify(imageArray));
    setUrlImages(imageArray);
    setImages(newFiles);
  };

  const onSubmit = async (values) => {
    console.log(images.length);
    const formData = new FormData();
    images.map((image) => {
      formData.append("image_files[]", image);
    });
    formData.append("content", values.content);

    console.table([...formData]);

    await socialApi.createPost(formData).then((response) => {
      if (response.success) {
        history.go(0);
      }
    });
  };

  console.log(errors);

  return (
    <Card p="1rem" height="12rem">
      {user.getRole() === "artist" || user.getRole() === "admin" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            w="100%"
            h="100%"
            templateColumns=".5fr repeat(2, 1fr) .5fr"
            templateRows="repeat(2, 1fr) 1.25fr"
          >
            <GridItem colSpan={1} rowSpan={2}>
              <Flex mb={4} justifyContent="center">
                <FormControl
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <FormLabel
                    color="secondaryLight.500"
                    _hover={{ color: "secondaryLight.700" }}
                    id="post_image"
                    role="button"
                    m="0"
                  >
                    <Box
                      border="1px dashed black"
                      width={"100%"}
                      height={"100%"}
                      borderColor={secondaryColor}
                      borderRadius={"10px"}
                      padding="10px"
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text>{"Elegir imagen"}</Text>
                    </Box>
                  </FormLabel>
                  <Input
                    type="file"
                    onChange={handleImageChange}
                    multiple
                    accept="image/*"
                    hidden
                  />
                  <Input {...register("fileString")} hidden />
                </FormControl>
              </Flex>
            </GridItem>
            <GridItem colSpan={3} rowSpan={2} colStart={2} rowStart={1}>
              <Box h="100%" w="100%">
                <FormControl>
                  <Textarea resize={"none"} {...register("content")} />
                </FormControl>
              </Box>
            </GridItem>
            <GridItem colSpan={3} rowStart={3}>
              <Flex flexWrap="wrap" h="4rem" alignItems="center">
                {urlImages.map((image, index) => (
                  <>
                    <Box key={index} p="2" w="7rem" h="100%" overflow="hidden">
                      <Image src={image} alt={`image_${index}`} />
                    </Box>
                    <Text color={secondaryColor} fontWeight={500}>
                      {images[index].name}
                    </Text>
                  </>
                ))}
              </Flex>
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} colStart={4} rowStart={3}>
              <Flex
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Button type="submit" colorScheme="secondaryLight">
                  Publicar
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </form>
      ) : (
        <>
          <Text fontWeight="500">
            Solo los artistas pueden publicar. Para hacer una publicación{" "}
            <Link href="/app/settings/inkdex-me" color={secondaryColor}>
              aplica como artista.
            </Link>{" "}
          </Text>
        </>
      )}
    </Card>
  );
};

export default NewPostForm;
